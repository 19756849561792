/* eslint-disable import/prefer-default-export */

export function isPreview() {
  const urlObject = new URL(window.location.href);
  const urlParts = urlObject.hostname.split('.');
  const subdomain = urlParts.length > 2 ? urlParts[0] : null;
  const preview = urlObject.searchParams.get('preview');
  return subdomain === 'preview' || preview;
}

export function getPreviewClassName() {
  const urlObject = new URL(window.location.href);
  const className = urlObject.searchParams.get('className');
  return className || 'pp40';
}
