import React from 'react';
import { PropTypes } from 'prop-types';

// NOTE: Maybe add proper xss parsing tool.
//       if we ever need a WYSIWYG Editor, this won't work as expected
const RenderHTML = (props) => {
  return props.html.split(/<br \/>/).map((item) => {
    return (
      <span key={item}>
        {item} <br />
      </span>
    );
  });
};

RenderHTML.propTypes = {
  html: PropTypes.string.isRequired,
};

RenderHTML.defaultProps = {};

export default RenderHTML;
