import React, { useState } from 'react';
import {
  faCalendarClock,
  faCarSide,
  faClipboardListCheck,
  faCommentHeart,
  faPersonWalkingLuggage,
  faRoute,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import history from './history';
import { googleGeocodeAddress } from '../hooks/useGoogleGeocoder';

export const newRideSteps = [
  {
    value: 0,
    label: 'Strecke',
    icon: <FontAwesomeIcon icon={faRoute} />,
  },
  {
    value: 1,
    label: 'Wegbegleiter',
    icon: <FontAwesomeIcon icon={faPersonWalkingLuggage} />,
  },
  {
    value: 2,
    label: 'Zeitangaben',
    icon: <FontAwesomeIcon icon={faCalendarClock} />,
  },
  {
    value: 3,
    label: 'Transportmittel',
    icon: <FontAwesomeIcon icon={faCarSide} />,
  },
  {
    value: 4,
    label: 'Vorlieben',
    icon: <FontAwesomeIcon icon={faCommentHeart} />,
  },
  {
    value: 5,
    label: 'Überprüfen',
    icon: <FontAwesomeIcon icon={faClipboardListCheck} />,
  },
];

export const newRequestSteps = [
  {
    value: 1,
    label: 'Strecke',
    icon: <FontAwesomeIcon icon={faRoute} />,
  },
  {
    value: 2,
    label: 'Zeitangaben',
    icon: <FontAwesomeIcon icon={faCalendarClock} />,
  },
  {
    value: 3,
    label: 'Vorlieben',
    icon: <FontAwesomeIcon icon={faCommentHeart} />,
  },
  {
    value: 4,
    label: 'Überprüfen',
    icon: <FontAwesomeIcon icon={faClipboardListCheck} />,
  },
];

export const allTimeSlots = [
  { day: 'sunday', departureTime: '', arrivalTime: '' },
  { day: 'monday', departureTime: '', arrivalTime: '' },
  { day: 'tuesday', departureTime: '', arrivalTime: '' },
  { day: 'wednesday', departureTime: '', arrivalTime: '' },
  { day: 'thursday', departureTime: '', arrivalTime: '' },
  { day: 'friday', departureTime: '', arrivalTime: '' },
  { day: 'saturday', departureTime: '', arrivalTime: '' },
];

export const defaultWeekdayIds = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
];

/** Starts with Monday */
export const allWeekdayIds = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

/** Starts with Sunday */
export const weekdayIds = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

/**
 *
 * @param {*} depDate  'DD.MM.YYYY'
 * @returns 'YYYY-MM-DD'
 */
export function parseDateFromDepDate(depDate) {
  return moment(depDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
}

export function parseDays(weekdays) {
  return weekdays.map((wd) => weekdayIds.findIndex((id) => id === wd)).join('');
}

export function parseWeekdays(days) {
  return days.split('').map((d) => weekdayIds[d]);
}

/**
 *
 * @param {*} dateTime 'DD.MM.YYYY HH:mm'
 * @returns 'HH:mm'
 */
export function parseDepTimeFromSortDay(dateTime) {
  const date = moment(dateTime, 'DD.MM.YYYY HH:mm');
  return date.format('HH:mm');
}

/**
 *
 * @param {*} depdate 'DD.MM.YYYY'
 * @param {*} deptime 'HH:mm'
 * @returns 'YYYY-MM-DD HH:mm'
 */
export function parseSortDayFromDepDateAndTime(depdate, deptime) {
  const date = moment(depdate, 'DD.MM.YYYY');
  const time = moment(deptime, 'HH:mm');
  date.set({
    hour: time.hour(),
    minute: time.minute(),
    second: time.second(),
  });
  return date.format('YYYY-MM-DD HH:mm');
}

/**
 *
 * @param {*} type regular or single
 * @param {*} days array of numbers from 0 - 6
 * @param {*} depdate  DD.MM.YYYY
 * @param {*} t i18nex.t
 * @returns
 */
export function parseDisplayDate(type, days, depdate, t) {
  if (!type) {
    return '';
  }
  return type === 'regular'
    ? days
        .split('')
        .map((day) => {
          return t(`searchOptions.weekdays.options.${weekdayIds[+day]}`);
        })
        .join(', ')
    : moment(depdate, 'DD.MM.YYYY')
        .locale(navigator.language)
        .format('DD. MMMM');
}

export function useWidgetParams() {
  const [widgetParams, setWidgetParams] = useState(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has('s') && urlSearchParams.has('d')) {
    const s = urlSearchParams.get('s');
    const d = urlSearchParams.get('d');
    urlSearchParams.delete('s');
    urlSearchParams.delete('d');
    Promise.all([googleGeocodeAddress(s), googleGeocodeAddress(d)])
      .then((results) => {
        const [dep, dest] = results;
        setWidgetParams({
          dep: dep.address,
          dest: dest.address,
          depg: `${dep.lat},${dep.lng}`,
          destg: `${dest.lat},${dest.lng}`,
          days: '12345',
          depdate: moment().format('DD.MM.YYYY'),
          deptime: '08:00',
          radius: '10',
          type: 'regular',
        });
      })
      .catch(() => {
        setWidgetParams({
          dep: s,
          dest: d,
          depg: '',
          destg: '',
          days: '12345',
          depdate: moment().format('DD.MM.YYYY'),
          deptime: '08:00',
          radius: '10',
          type: 'regular',
        });
      });
  }
  return widgetParams;
}

export function useReadURL() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const p = {
    /** Departure: dep "Street Number, City, Country" */
    dep: searchParams.get('dep') || '',
    /** Destination: dest  "Street Number, City, Country" */
    dest: searchParams.get('dest') || '',
    /** Departure Geo Coordinates: depg "Longitude,Latitude" */
    depg: searchParams.get('depg') || '',
    /** Destination Geo Coordinates: destg "Longitude,Latitude" */
    destg: searchParams.get('destg') || '',
    /** Week Days: days "0" for Sunday and "6" for Saturday (Mo. - Fr. => "12345") */
    days: searchParams.get('days') || '12345',
    /** Departure Date: depdate "DD.MM.YYYY" */
    depdate: searchParams.get('depdate') || moment().format('DD.MM.YYYY'),
    /** Departure Time: depdate "hh:mm" */
    deptime: searchParams.get('deptime') || '08:00',
    /** Radius in km: radius "5" */
    radius: searchParams.get('radius') || '10',
    /** Type of search: regular or single */
    type: searchParams.get('type') || 'regular',
  };
  return p;
}

export function updateURL(searchOptions) {
  const searchParams = new URLSearchParams();
  /** Departure: dep "Street Number, City, Country" */
  searchParams.set('dep', searchOptions.dep);
  /** Destination: dest  "Street Number, City, Country" */
  searchParams.set('dest', searchOptions.dest);
  /** Departure Geo Coordinates: depg "Longitude,Latitude" */
  searchParams.set('depg', searchOptions.depg);
  /** Destination Geo Coordinates: destg "Longitude,Latitude" */
  searchParams.set('destg', searchOptions.destg);
  /** Week Days: days "0" for Sunday and "6" for Saturday (Mo. - Fr. => "12345") */
  searchParams.set('days', `${searchOptions.days}`);
  /** Departure Date: depdate "DD.MM.YYYY" */
  searchParams.set('depdate', searchOptions.depdate);
  /** Departure Time: depdate "hh:mm" */
  searchParams.set('deptime', searchOptions.deptime);
  /** Radius in km: radius "5" */
  searchParams.set('radius', searchOptions.radius);
  /** Type of search: regular or single */
  searchParams.set('type', searchOptions.type);
  history.push(`?${searchParams.toString()}`);
}

export function useSearchHelper() {
  const [searchOptions, setSearchOptions] = useState(useReadURL());

  const updateSearchOptions = (so) => {
    updateURL(so);
    setSearchOptions({ ...so });
  };

  return { searchOptions, updateSearchOptions };
}
