import React, { useEffect, useRef, useState } from 'react';
import Page from '../../components/Layout/Page';
import Button from '../../components/Buttons/Button';
import usePageTitle from '../../hooks/usePageTitle';
import Form from '../../components/Inputs/Form';
import Input from '../../components/Inputs/Input';

const defaultVariables = {
  Theme_text: '#000000',
  Theme_background: '#ffffff',
  Theme_content_background: '#b5b5b5',
  Theme_focus_border: '#ffffff',
  Theme_primary_text: '#ffffff',
  Theme_primary: '#000000',
  Theme_primary_darker: '#000000',
  Theme_secondary_text: '#ffffff',
  Theme_secondary: '#404040',
  Theme_highlight_text: '#ffffff',
  Theme_highlight: '#000000',
  Theme_success_text: '#ffffff',
  Theme_success: '#000000',
  Theme_error_text: '#ffffff',
  Theme_error: '#000000',
  Theme_stars: '#fbde23',
  Theme_active_text: '#ffffff',
  Theme_active: '#000000',
  Theme_inactive_text: '#ffffff',
  Theme_inactive: '#4a4a4a',
  Theme_card_inactive_text: '#ffffff',
  Theme_card_inactive: '#000000',
  Theme_card_active_text: '#ffffff',
  Theme_card_active: '#000000',
  Theme_default_text: '#ffffff',
  Theme_default: '#000000',
  Theme_action_text: '#ffffff',
  Theme_action: '#262626',
  Theme_danger_text: '#ffffff',
  Theme_danger: '#4a4a4a',
  Theme_menu_text: '#ffffff',
  Theme_menu_background: '#000000',
  Theme_menu_background_hover: '#454545',
  Theme_menu_flap_one: '#383838',
  Theme_menu_flap_two: '#8c8c8c',
  Theme_home_gradient_text: '#ffffff',
  Theme_home_gradient: '#000000',
  Theme_login_gradient_text: '#ffffff',
  Theme_login_gradient: '#000000',
  Subheader_background: '#000000',
};

const DerivativeCreator = () => {
  usePageTitle('Derivative Creator');
  let { current: styleSheet } = useRef();
  const [variables, setVariables] = useState('');
  const [variablesText, setVariablesText] = useState('');

  const loadStyles = () => {
    const savedVariables = localStorage.getItem('newVariables');
    if (savedVariables) {
      setVariables(JSON.parse(savedVariables));
    } else {
      setVariables(defaultVariables);
    }
  };

  useEffect(() => {
    loadStyles();
  }, []);

  const saveStyles = (target) => {
    const newVariables = {
      Theme_text: target.Theme_text.value,
      Theme_background: target.Theme_background.value,
      Theme_content_background: target.Theme_content_background.value,
      Theme_focus_border: target.Theme_focus_border.value,

      Theme_primary_text: target.Theme_primary_text.value,
      Theme_primary: target.Theme_primary.value,
      Theme_primary_darker: target.Theme_primary_darker.value,
      Theme_secondary_text: target.Theme_secondary_text.value,
      Theme_secondary: target.Theme_secondary.value,
      Theme_highlight_text: target.Theme_highlight_text.value,
      Theme_highlight: target.Theme_highlight.value,

      Theme_success_text: target.Theme_success_text.value,
      Theme_success: target.Theme_success.value,
      Theme_error_text: target.Theme_error_text.value,
      Theme_error: target.Theme_error.value,

      Theme_stars: target.Theme_stars.value,

      Theme_active_text: target.Theme_active_text.value,
      Theme_active: target.Theme_active.value,
      Theme_inactive_text: target.Theme_inactive_text.value,
      Theme_inactive: target.Theme_inactive.value,

      Theme_card_inactive_text: target.Theme_card_inactive_text.value,
      Theme_card_inactive: target.Theme_card_inactive.value,
      Theme_card_active_text: target.Theme_card_active_text.value,
      Theme_card_active: target.Theme_card_active.value,

      Theme_default_text: target.Theme_default_text.value,
      Theme_default: target.Theme_default.value,
      Theme_action_text: target.Theme_action_text.value,
      Theme_action: target.Theme_action.value,
      Theme_danger_text: target.Theme_danger_text.value,
      Theme_danger: target.Theme_danger.value,

      Theme_menu_text: target.Theme_menu_text.value,
      Theme_menu_background: target.Theme_menu_background.value,
      Theme_menu_background_hover: target.Theme_menu_background_hover.value,
      Theme_menu_flap_one: target.Theme_menu_flap_one.value,
      Theme_menu_flap_two: target.Theme_menu_flap_two.value,

      Theme_home_gradient_text: target.Theme_home_gradient_text.value,
      Theme_home_gradient: target.Theme_home_gradient.value,

      Theme_login_gradient_text: target.Theme_login_gradient_text.value,
      Theme_login_gradient: target.Theme_login_gradient.value,

      Subheader_background: target.Subheader_background.value,
    };
    localStorage.setItem('newVariables', JSON.stringify(newVariables));
  };

  const addStyles = (target) => {
    if (!styleSheet) {
      const theme = document.getElementById('theme');
      if (theme) {
        styleSheet = theme;
      } else {
        // eslint-disable-next-line no-const-assign
        styleSheet = document.createElement('style');
      }
    }
    // Add styles dynamically using JavaScript
    const customStyles = `
    :root {
      --Theme_text: ${target.Theme_text.value};
      --Theme_background: ${target.Theme_background.value};
      --Theme_content_background: ${target.Theme_content_background.value};
      --Theme_focus_border: ${target.Theme_focus_border.value};
    
      --Theme_primary_text: ${target.Theme_primary_text.value};
      --Theme_primary: ${target.Theme_primary.value};
      --Theme_primary_darker: ${target.Theme_primary_darker.value};
      --Theme_secondary_text: ${target.Theme_secondary_text.value};
      --Theme_secondary: ${target.Theme_secondary.value};
      --Theme_highlight_text: ${target.Theme_highlight_text.value};
      --Theme_highlight: ${target.Theme_highlight.value};
    
      --Theme_success_text: ${target.Theme_success_text.value};
      --Theme_success: ${target.Theme_success.value};
      --Theme_error_text: ${target.Theme_error_text.value};
      --Theme_error: ${target.Theme_error.value};
    
      --Theme_stars: ${target.Theme_stars.value};
    
      --Theme_active_text: ${target.Theme_active_text.value};
      --Theme_active: ${target.Theme_active.value};
      --Theme_inactive_text: ${target.Theme_inactive_text.value};
      --Theme_inactive: ${target.Theme_inactive.value};

      --Theme_card_inactive_text: ${target.Theme_card_inactive_text.value};
      --Theme_card_inactive: ${target.Theme_card_inactive.value};
      --Theme_card_active_text: ${target.Theme_card_active_text.value};
      --Theme_card_active: ${target.Theme_card_active.value};
    
      --Theme_default_text: ${target.Theme_default_text.value};
      --Theme_default: ${target.Theme_default.value};
      --Theme_action_text: ${target.Theme_action_text.value};
      --Theme_action: ${target.Theme_action.value};
      --Theme_danger_text: ${target.Theme_danger_text.value};
      --Theme_danger: ${target.Theme_danger.value};
    
      --Theme_menu_text: ${target.Theme_menu_text.value};
      --Theme_menu_background: ${target.Theme_menu_background.value};
      --Theme_menu_background_hover: ${target.Theme_menu_background_hover.value};
      --Theme_menu_flap_one: ${target.Theme_menu_flap_one.value};
      --Theme_menu_flap_two: ${target.Theme_menu_flap_two.value};
    
      --Theme_home_gradient_text: ${target.Theme_home_gradient_text.value};
      --Theme_home_gradient: ${target.Theme_home_gradient.value};
    
      --Theme_login_gradient_text: ${target.Theme_login_gradient_text.value};
      --Theme_login_gradient: ${target.Theme_login_gradient.value};
    
      --Subheader_background: ${target.Subheader_background.value};
      }
    `;
    styleSheet.id = 'theme';
    styleSheet.type = 'text/css';
    styleSheet.innerText = customStyles;
    document.body.appendChild(styleSheet);
    setVariablesText(customStyles);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addStyles(event.target);
    saveStyles(event.target);
  };

  const removeStyles = () => {
    const theme = document.getElementById('theme');
    if (theme) {
      document.body.removeChild(theme);
    }
    setVariablesText('');
  };

  if (!variables) {
    return null;
  }

  return (
    <div className="DerivativeCreator">
      <Page>
        <h1 className="underline">Portal Creator</h1>
        <Button to="/" shape="text">
          zur Statseite
        </Button>
        <p>Teste die Farben für ein neues Portal.</p>
        <Form onSubmit={handleSubmit}>
          <div className="Grid">
            <fieldset>
              <legend>Basic </legend>
              <Input
                type="color"
                id="Theme_text"
                name="Theme_text"
                label="Theme_text"
                defaultValue={variables.Theme_text}
              />
              <Input
                type="color"
                id="Theme_background"
                name="Theme_background"
                label="Theme_background"
                defaultValue={variables.Theme_background}
              />
              <Input
                type="color"
                id="Theme_content_background"
                name="Theme_content_background"
                label="Theme_content_background"
                defaultValue={variables.Theme_content_background}
              />
              <Input
                type="color"
                id="Theme_focus_border"
                name="Theme_focus_border"
                label="Theme_focus_border"
                defaultValue={variables.Theme_focus_border}
              />
            </fieldset>
            <fieldset>
              <legend>Theme</legend>
              <Input
                type="color"
                id="Theme_primary_text"
                name="Theme_primary_text"
                label="Theme_primary_text"
                defaultValue={variables.Theme_primary_text}
              />
              <Input
                type="color"
                id="Theme_primary"
                name="Theme_primary"
                label="Theme_primary"
                defaultValue={variables.Theme_primary}
              />
              <Input
                type="color"
                id="Theme_primary_darker"
                name="Theme_primary_darker"
                label="Theme_primary_darker"
                defaultValue={variables.Theme_primary_darker}
              />
              <Input
                type="color"
                id="Theme_secondary_text"
                name="Theme_secondary_text"
                label="Theme_secondary_text"
                defaultValue={variables.Theme_secondary_text}
              />
              <Input
                type="color"
                id="Theme_secondary"
                name="Theme_secondary"
                label="Theme_secondary"
                defaultValue={variables.Theme_secondary}
              />
              <Input
                type="color"
                id="Theme_highlight_text"
                name="Theme_highlight_text"
                label="Theme_highlight_text"
                defaultValue={variables.Theme_highlight_text}
              />
              <Input
                type="color"
                id="Theme_highlight"
                name="Theme_highlight"
                label="Theme_highlight"
                defaultValue={variables.Theme_highlight}
              />
            </fieldset>
            <fieldset>
              <legend>Success / Error</legend>
              <Input
                type="color"
                id="Theme_success_text"
                name="Theme_success_text"
                label="Theme_success_text"
                defaultValue={variables.Theme_success_text}
              />
              <Input
                type="color"
                id="Theme_success"
                name="Theme_success"
                label="Theme_success"
                defaultValue={variables.Theme_success}
              />
              <Input
                type="color"
                id="Theme_error_text"
                name="Theme_error_text"
                label="Theme_error_text"
                defaultValue={variables.Theme_error_text}
              />
              <Input
                type="color"
                id="Theme_error"
                name="Theme_error"
                label="Theme_error"
                defaultValue={variables.Theme_error}
              />
            </fieldset>
            <fieldset>
              <legend>Active / Inactive</legend>
              <Input
                type="color"
                id="Theme_active_text"
                name="Theme_active_text"
                label="Theme_active_text"
                defaultValue={variables.Theme_active_text}
              />
              <Input
                type="color"
                id="Theme_active"
                name="Theme_active"
                label="Theme_active"
                defaultValue={variables.Theme_active}
              />
              <Input
                type="color"
                id="Theme_inactive_text"
                name="Theme_inactive_text"
                label="Theme_inactive_text"
                defaultValue={variables.Theme_inactive_text}
              />
              <Input
                type="color"
                id="Theme_inactive"
                name="Theme_inactive"
                label="Theme_inactive"
                defaultValue={variables.Theme_inactive}
              />
            </fieldset>
            <fieldset>
              <legend>Card: Trip / ÖPNV </legend>
              <Input
                type="color"
                id="Theme_card_inactive_text"
                name="Theme_card_inactive_text"
                label="Theme_card_inactive_text"
                defaultValue={variables.Theme_card_inactive_text}
              />
              <Input
                type="color"
                id="Theme_card_inactive"
                name="Theme_card_inactive"
                label="Theme_card_inactive"
                defaultValue={variables.Theme_card_inactive}
              />
              <Input
                type="color"
                id="Theme_card_active_text"
                name="Theme_card_active_text"
                label="Theme_card_active_text"
                defaultValue={variables.Theme_card_active_text}
              />
              <Input
                type="color"
                id="Theme_card_active"
                name="Theme_card_active"
                label="Theme_card_active"
                defaultValue={variables.Theme_card_active}
              />
            </fieldset>
            <fieldset>
              <legend>Buttons</legend>
              <Input
                type="color"
                id="Theme_default_text"
                name="Theme_default_text"
                label="Theme_default_text"
                defaultValue={variables.Theme_default_text}
              />
              <Input
                type="color"
                id="Theme_default"
                name="Theme_default"
                label="Theme_default"
                defaultValue={variables.Theme_default}
              />
              <Input
                type="color"
                id="Theme_action_text"
                name="Theme_action_text"
                label="Theme_action_text"
                defaultValue={variables.Theme_action_text}
              />
              <Input
                type="color"
                id="Theme_action"
                name="Theme_action"
                label="Theme_action"
                defaultValue={variables.Theme_action}
              />
              <Input
                type="color"
                id="Theme_danger_text"
                name="Theme_danger_text"
                label="Theme_danger_text"
                defaultValue={variables.Theme_danger_text}
              />
              <Input
                type="color"
                id="Theme_danger"
                name="Theme_danger"
                label="Theme_danger"
                defaultValue={variables.Theme_danger}
              />
            </fieldset>
            <fieldset>
              <legend>Menu / Details</legend>
              <Input
                type="color"
                id="Theme_menu_text"
                name="Theme_menu_text"
                label="Theme_menu_text"
                defaultValue={variables.Theme_menu_text}
              />
              <Input
                type="color"
                id="Theme_menu_background"
                name="Theme_menu_background"
                label="Theme_menu_background"
                defaultValue={variables.Theme_menu_background}
              />
              <Input
                type="color"
                id="Theme_menu_background_hover"
                name="Theme_menu_background_hover"
                label="Theme_menu_background_hover"
                defaultValue={variables.Theme_menu_background_hover}
              />
              <Input
                type="color"
                id="Theme_menu_flap_one"
                name="Theme_menu_flap_one"
                label="Theme_menu_flap_one"
                defaultValue={variables.Theme_menu_flap_one}
              />
              <Input
                type="color"
                id="Theme_menu_flap_two"
                name="Theme_menu_flap_two"
                label="Theme_menu_flap_two"
                defaultValue={variables.Theme_menu_flap_two}
              />
            </fieldset>
            <fieldset>
              <legend>Home / Landing Page</legend>
              <Input
                type="color"
                id="Theme_home_gradient_text"
                name="Theme_home_gradient_text"
                label="Theme_home_gradient_text"
                defaultValue={variables.Theme_home_gradient_text}
              />
              <Input
                type="color"
                id="Theme_home_gradient"
                name="Theme_home_gradient"
                label="Theme_home_gradient"
                defaultValue={variables.Theme_home_gradient}
              />
              <br />
              <Input
                type="color"
                id="Subheader_background"
                name="Subheader_background"
                label="Subheader_background"
                defaultValue={variables.Subheader_background}
              />
            </fieldset>
            <fieldset>
              <legend>Login / SignUp</legend>
              <Input
                type="color"
                id="Theme_login_gradient_text"
                name="Theme_login_gradient_text"
                label="Theme_login_gradient_text"
                defaultValue={variables.Theme_login_gradient_text}
              />
              <Input
                type="color"
                id="Theme_login_gradient"
                name="Theme_login_gradient"
                label="Theme_login_gradient"
                defaultValue={variables.Theme_login_gradient}
              />
            </fieldset>
            <fieldset>
              <legend>Rating</legend>
              <Input
                type="color"
                id="Theme_stars"
                name="Theme_stars"
                label="Theme_stars"
                defaultValue={variables.Theme_stars}
              />
            </fieldset>
          </div>
          <br />
          <Button type="submit">Theme Anwenden</Button>{' '}
          <Button onClick={removeStyles} shape="warning">
            Theme Entfernen
          </Button>
        </Form>
        <br />
        <Button to="/">zur Statseite</Button>
        <br />
        <br />
        <hr />
        <br />
        <Input
          type="textarea"
          name="variables"
          id="id"
          label="Theme Variables"
          value={variablesText}
        />
      </Page>
    </div>
  );
};

export default DerivativeCreator;
