import '../assets/cryptojs';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import reqwest from 'reqwest';
import { initDB } from 'react-indexed-db';
import settings from './settings';
import App from './App';
import SessionContext from './contexts/SessionContext';
import useSession from './hooks/useSession';
import useDerivative from './hooks/useDerivative';
import addMatomoScript from './logic/matomo';
import customHistory from './logic/history';
import styleOverride from './logic/styleOverride';
import textOverride from './logic/textOverride';
import faviconOverride from './logic/faviconOverride';
import { getPreviewClassName, isPreview } from './logic/helpers';
import useLanguage from './hooks/useLanguage';
import LanguageContext from './contexts/LanguageContext';
import useGoogleMaps from './hooks/useGoogleMaps';

// eslint-disable-next-line react/prop-types
const Bootstrap = () => {
  const { setToken, userdata, setUserData, clearSession } = useSession();
  const derivative = useDerivative();
  const { languageReady, selected, setSelected, setLanguages } = useLanguage();
  const { googleReady, setGoogleLanguage } = useGoogleMaps();
  const [dbReady, setDbReady] = useState(false);
  const [derivativeReady, setDerivativeReady] = useState(false);
  const [sessionData, setSessionData] = useState(userdata);
  const setSessionDataAndStore = (sd) => {
    if (sd) {
      setUserData(sd);
      setToken(sd?.token);
    } else {
      clearSession();
    }
    setSessionData(sd);
  };
  const [updateCheck, setUpdateCheck] = useState(Math.random());

  const checkSession = async () => {
    try {
      if (!sessionData) {
        const sessionUser = await reqwest({
          method: 'GET',
          url: settings.general.sessionUser,
        });
        if (sessionUser && sessionUser.eMail) {
          setSessionDataAndStore(sessionUser);
        } else {
          setSessionData(undefined);
          clearSession();
        }
        return;
      }

      const tokenIsValid = await reqwest({
        method: 'GET',
        url: settings.general.checkToken,
        data: {
          Token: sessionData.token,
        },
      });

      if (!tokenIsValid) {
        setSessionData(undefined);
        clearSession();
      }
    } catch (e) {
      setSessionData(undefined);
      clearSession();
    }
  };

  useEffect(() => {
    checkSession();
  }, [updateCheck]);

  useEffect(() => {
    setGoogleLanguage(selected);
  }, [selected]);

  useEffect(() => {
    customHistory.listen(() => {
      setUpdateCheck(Math.random);
      // checkSession() here will result in logout, because "sessionData" will be undefined for some reason.
    });
  }, []);

  const initDerivative = async (d) => {
    if (d.matomoSiteId && process.env.NODE_ENV !== 'development') {
      addMatomoScript(d.matomoSiteId);
    }

    if (d.className) {
      /* Set class name. This is used by the CSS variables */
      document.querySelector('html').classList.add(d.className);
      /* Set website title */
      document.title = d.name ? d.name : document.title;
      /* Override website favicon */
      faviconOverride(d.className);
      /* Override css variables if available in cdn folder */
      styleOverride(d.className);

      /* Add available langauges */
      setLanguages(d.languages ? d.languages : ['de']);
      try {
        /* Override texts if available in cdn folder. */
        await textOverride(d.className, d.languages ? d.languages : ['de']);
      } catch (e) {
        console.log(e);
      }
    }
    setDerivativeReady(true);
  };

  useEffect(() => {
    /** Setup Local DB für caching profile images */
    initDB(settings.DBConfig);
    setDbReady(true);
    /** Setup Derivatives */
    const preview = isPreview();
    if (preview) {
      const className = getPreviewClassName();
      reqwest(`${settings.preview.specificDerivate}?className=${className}`)
        .then((result) => {
          if (result) {
            localStorage.setItem('derivative', JSON.stringify(result));
            initDerivative(result);
          } else {
            initDerivative(derivative);
          }
        })
        .fail(() => {});
    } else if (process.env.NODE_ENV === 'development') {
      initDerivative(derivative);
    } else {
      reqwest({
        method: 'GET',
        url: settings.general.derivateSettings,
      })
        .then((result) => {
          if (result) {
            localStorage.setItem('derivative', JSON.stringify(result));
            initDerivative(result);
          }
        })
        .fail(() => {});
    }
  }, []);

  if (!languageReady || !dbReady || !derivativeReady || !googleReady) {
    return <></>;
  }

  return (
    <SessionContext.Provider
      value={{
        sessionData,
        setSessionData: setSessionDataAndStore,
      }}
    >
      <LanguageContext.Provider
        value={{ selectedLanguage: selected, setSelectedLanguage: setSelected }}
      >
        <App />
      </LanguageContext.Provider>
    </SessionContext.Provider>
  );
};

export default hot(module)(Bootstrap);
