import validator from 'validator';
import i18next from 'i18next';
import moment from 'moment';

export default async function validate(value, rules) {
  if (rules === undefined) {
    return {
      isValid: true,
      error: '',
    };
  }

  return new Promise((resolve, reject) => {
    const promises = rules.map((rule) => {
      return new Promise((res, rej) => {
        // REQUIRED VALIDATION
        if (rule.required) {
          if (
            value === '' ||
            value === undefined ||
            value === null ||
            value.length === 0 ||
            value === false
          ) {
            if (rule.error) {
              rej(rule.error);
            } else {
              rej(i18next.t('global.errors.required'));
            }
            return;
          }
          res();
        }

        // TYPE VALIDATION
        if (rule.type) {
          switch (rule.type) {
            case 'email':
              if (value && !validator.isEmail(value)) {
                if (rule.error) {
                  rej(rule.error);
                } else {
                  rej(i18next.t('global.errors.type.email'));
                }
                return;
              }
              break;
            case 'date': {
              if (value && !moment(value, 'DD.MM.YYYY', true).isValid()) {
                if (rule.error) {
                  rej(rule.error);
                } else {
                  rej(i18next.t('global.errors.type.date'));
                }
                return;
              }
              break;
            }
            case 'time': {
              if (value && !moment(value, 'HH:mm', true).isValid()) {
                if (rule.error) {
                  rej(rule.error);
                } else {
                  rej(i18next.t('global.errors.type.time'));
                }
                return;
              }
              break;
            }
            case 'url':
              if (
                value &&
                !validator.isURL(value, {
                  protocols: ['http', 'https'],
                  require_protocol: true,
                })
              ) {
                if (rule.error) {
                  rej(rule.error);
                } else {
                  rej(i18next.t('global.errors.type.url'));
                }
                return;
              }
              break;
            case 'phone': {
              const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
              phoneRegex.lastIndex = 0;
              if (value && !phoneRegex.test(value)) {
                if (rule.error) {
                  rej(rule.error);
                } else {
                  rej(i18next.t('global.errors.type.phone'));
                }
                return;
              }
              break;
            }
            case 'notzero': {
              const notZeroRegex = /^(?!0+$)[a-zA-Z0-9]+$/g;
              notZeroRegex.lastIndex = 0;
              if (value && !notZeroRegex.test(value)) {
                if (rule.error) {
                  rej(rule.error);
                } else {
                  rej(i18next.t('global.errors.type.notzero'));
                }
                return;
              }
              break;
            }
            case 'number': {
              const numberregex = /^[0-9]*$/g;
              numberregex.lastIndex = 0;
              if (value && !numberregex.test(value)) {
                if (rule.error) {
                  rej(rule.error);
                } else {
                  rej(i18next.t('global.errors.type.number'));
                }
                return;
              }
              break;
            }
            default:
              break;
          }
          res();
        }

        // REGEX VALIDATION
        if (rule.regex) {
          rule.regex.lastIndex = 0;
          if (!rule.regex.test(value)) {
            rej(rule.error);
            return;
          }
          res();
        }

        // MIN VALIDATION
        if (rule.min) {
          if (typeof value === 'string' && value.length < rule.min) {
            rej(rule.error);
            return;
          }
          if (typeof value === 'number' && value < rule.min) {
            rej(rule.error);
            return;
          }
          res();
        }

        // MAX VALIDATION
        if (rule.max) {
          if (typeof value === 'string' && value.length > rule.max) {
            rej(rule.error);
            return;
          }
          if (typeof value === 'number' && value > rule.max) {
            rej(rule.error);
            return;
          }
          res();
        }

        // CUSTOM VALIDATION
        if (rule.custom && typeof rule.custom === 'function') {
          if (!rule.custom(value)) {
            rej(rule.error);
          } else {
            res();
          }
        }

        // CUSTOM ASYNC VALIDATION
        if (rule.customAsync && typeof rule.customAsync === 'function') {
          rule.customAsync(value).then((customValue) => {
            if (!customValue) {
              rej(rule.error);
            } else {
              res();
            }
          });
        }
      });
    });

    Promise.all(promises).then(resolve).catch(reject);
  })
    .then(() => {
      return {
        isValid: true,
        error: '',
      };
    })
    .catch((error) => {
      return {
        isValid: false,
        error,
      };
    });
}
