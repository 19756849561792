/**
 * A Derivative is a derivate of the Project
 * @typedef {Object} Derivative
 * @property {string} name
 * @property {string} className
 * @property {array} urls
 */

const defaultDerivative = {
  appGuid: '15C84022-4196-468E-B4C3-C4AB31E9ABFF',
  displayName: 'Pendlerportal',
  name: 'Pendlerportal',
  className: 'pp40',
  urls: ['https://pendlerportal.de'],
  pois: [],
  socialLink: { facebook: 'https://de-de.facebook.com/Pendlerportal' },
};

/**
 * @name useDerivative
 * @description useDerivative returns the current derivative, if none present, returns ''
 * @returns {Derivative} The found or not found Derivative
 */
const useDerivative = () => {
  /*
  // Use state and effect if PP is refactored to all functional components
  const [derivative, setDerivative] = useState(defaultDerivative);
  const devDerivative = localStorage.getItem('derivative');

  useEffect(() => {
    if (devDerivative) {
      setDerivative({ ...defaultDerivative, ...JSON.parse(devDerivative) });
    } else {
      setDerivative(defaultDerivative);
    }
  }, [devDerivative]);

  return derivative;
  */
  const derivative = localStorage.getItem('derivative');
  if (derivative) {
    return { ...defaultDerivative, ...JSON.parse(derivative) };
  }
  return defaultDerivative;
};

export default useDerivative;
