import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import useDerivative from '../../hooks/useDerivative';

const defaultMapLocation = { lat: 52.52437, lng: 13.41053 };

function MapComponent({
  mapId,
  zoom,
  startCoordinates,
  destCoordinates,
  waypoints,
  polyline,
  mode,
  draggable,
  gatherShapeData,
  gatherDistanceData,
  gatherTimeData,
  height,
  markers,
}) {
  const map = useRef(null);
  const mapElement = useRef(null);
  const mapMarkers = useRef([]);
  const mapRoute = useRef(null);
  const googleRef = useRef({
    directionsService: null,
    directionsRenderer: null,
  });
  const [shapeData, setShapeData] = useState('');
  const [distanceData, setDistanceData] = useState('');
  const [timeData, setTimeData] = useState('');
  const currentDerivative = useDerivative();

  /**
   * Callbacks:
   * All are triggered after route is calculated with provided "startCoordinates", "destCoordinates" and "waypoints".
   */
  useEffect(() => {
    gatherShapeData(shapeData);
  }, [shapeData]);
  useEffect(() => {
    gatherDistanceData(distanceData);
  }, [distanceData]);
  useEffect(() => {
    gatherTimeData(timeData);
  }, [timeData]);

  const computeTotalDistance = (result) => {
    let total = 0;
    const myRoute = result.routes[0];

    for (let i = 0; i < myRoute.legs.length; i += 1) {
      total += myRoute.legs[i].distance.value;
    }
    total /= 1000;
    setDistanceData(Number(total.toFixed(1)));
  };

  const computeTotalTime = (result) => {
    let total = 0;
    const myRoute = result.routes[0];

    for (let i = 0; i < myRoute.legs.length; i += 1) {
      total += myRoute.legs[i].duration.value;
    }
    total /= 60;
    setTimeData(Number(total.toFixed(1)));
  };

  const initMap = () => {
    // init google maps
    if (typeof google !== 'undefined') {
      map.current = new google.maps.Map(mapElement.current, {
        mapId,
        center: {
          lat: currentDerivative?.mapLocation
            ? currentDerivative?.mapLocation[0]
            : defaultMapLocation.lat,
          lng: currentDerivative?.mapLocation
            ? currentDerivative?.mapLocation[1]
            : defaultMapLocation.lng,
        },
        zoom: currentDerivative?.mapZoom || zoom,
      });
      googleRef.current.directionsService = new google.maps.DirectionsService();

      googleRef.current.directionsRenderer = new google.maps.DirectionsRenderer(
        {
          draggable,
        },
      );
      // This probably triggers to many rout calculation. Also would only be needed on draggable: true
      //  googleRef.current.directionsRenderer.addListener(
      //     'directions_changed',
      //     () => {
      //       const directions =
      //         googleRef.current.directionsRenderer.getDirections();
      //       if (directions) {
      //         computeTotalDistance(directions);
      //         computeTotalTime(directions);
      //         setShapeData(directions.routes[0].overview_polyline);
      //       }
      //     },
      //   );
    }
  };

  // Calculates and updates route on map with provided "startCoordinates", "destCoordinates", "waypoints" and "mode"
  useEffect(() => {
    if (!map.current) {
      initMap();
    }
    if (googleRef.current) googleRef.current.directionsRenderer.setMap(null);
    if (!startCoordinates || !destCoordinates) {
      return; // missing coordinates
    }
    googleRef.current.directionsRenderer.setMap(map.current);
    googleRef.current.directionsService.route(
      {
        origin: {
          query: startCoordinates,
        },
        destination: {
          query: destCoordinates,
        },
        travelMode:
          mode === 'driving'
            ? google.maps.TravelMode.DRIVING
            : google.maps.TravelMode.TRANSIT,
        waypoints,
        // optimizeWaypoints: true,
        provideRouteAlternatives: true,
      },
      (response, status) => {
        if (status === 'OK') {
          googleRef.current.directionsRenderer.setDirections(response);
          computeTotalDistance(response);
          computeTotalTime(response);
          setShapeData(response.routes[0].overview_polyline);
        } else {
          // API request for maps failed
        }
      },
    );
  }, [startCoordinates, destCoordinates, waypoints, mode]);

  // update route on map with provided "polyline"
  useEffect(() => {
    if (!map.current) {
      initMap();
    }
    if (mapRoute.current) mapRoute.current.setMap(null); // remove old route
    if (!polyline) {
      return;
    }
    const path = google.maps.geometry.encoding.decodePath(polyline);
    mapRoute.current = new google.maps.Polyline({
      path,
      strokeColor: '#3c6fb6',
      strokeOpacity: 0.8,
      strokeWeight: 6,
    });
    mapRoute.current.setMap(map.current);
    const bounds = new google.maps.LatLngBounds();
    path.forEach((r) => {
      bounds.extend(r);
    });
    map.current.fitBounds(bounds);

    // remove markers from map
    mapMarkers.current.forEach((mapMarker) => mapMarker.setMap(null));
    if (Array.isArray(markers)) {
      // add optional markers
      mapMarkers.current = markers
        .filter((marker) => marker.lat && marker.lng)
        .map(
          (marker) =>
            new google.maps.marker.AdvancedMarkerElement({
              map: map.current,
              title: marker.address,
              position: new google.maps.LatLng(marker.lat, marker.lng),
            }),
        );
    }
    const startMarker = new google.maps.marker.AdvancedMarkerElement({
      map: map.current,
      position: path[0],
    });
    mapMarkers.current.push(startMarker);
    const destMarker = new google.maps.marker.AdvancedMarkerElement({
      map: map.current,
      position: path[path.length - 1],
    });
    mapMarkers.current.push(destMarker);
  }, [polyline]);

  // Updates markers on map
  useEffect(() => {
    if (!Array.isArray(markers)) {
      return;
    }
    if (polyline) {
      return; // If polyline exists skip this. Markers will be set on polyline update.
    }
    if (!map.current) {
      initMap();
    }
    if (!markers) {
      if (mapMarkers.current) {
        mapMarkers.current.forEach((mapMarker) => mapMarker.setMap(null));
        mapMarkers.current = [];
      }
      return;
    }
    // remove markers from map
    mapMarkers.current.forEach((mapMarker) => mapMarker.setMap(null));
    // create new Map Markers
    mapMarkers.current = markers
      .filter((marker) => marker.lat && marker.lng)
      .map(
        (marker) =>
          new google.maps.Marker({
            map: map.current,
            title: marker.address,
            position: new google.maps.LatLng(marker.lat, marker.lng),
          }),
      );
    if (mapMarkers.current.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      mapMarkers.current.forEach((mapMarker) => {
        bounds.extend(mapMarker.position);
      });
      map.current.fitBounds(bounds);
    }
    if (markers.length === 1) {
      map.current.setZoom(15);
    }
  }, [markers]);
  return <div ref={mapElement} className={`Map ${height}`} />;
}

MapComponent.propTypes = {
  mapId: PropTypes.string,
  height: PropTypes.oneOf(['fixed', 'auto']),
  zoom: PropTypes.number,
  mode: PropTypes.string,
  draggable: PropTypes.bool,
  startCoordinates: PropTypes.string,
  destCoordinates: PropTypes.string,
  waypoints: PropTypes.arrayOf([
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      stopover: PropTypes.bool.isRequired,
    }),
  ]),
  polyline: PropTypes.string,
  gatherShapeData: PropTypes.func,
  gatherDistanceData: PropTypes.func,
  gatherTimeData: PropTypes.func,
  markers: PropTypes.arrayOf([
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      lat: PropTypes.string.isRequired,
      lng: PropTypes.string.isRequired,
    }),
  ]),
};

MapComponent.defaultProps = {
  mapId: 'pp_map',
  height: 'fixed',
  zoom: 6,
  startCoordinates: '',
  destCoordinates: '',
  waypoints: undefined,
  polyline: undefined,
  mode: 'driving',
  draggable: false,
  markers: [],
  gatherShapeData: () => {
    return null;
  },
  gatherDistanceData: () => {
    return null;
  },
  gatherTimeData: () => {
    return null;
  },
};

export default MapComponent;
