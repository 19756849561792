/* eslint-disable jsx-a11y/no-autofocus */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import validate from '../../../logic/validator';

import Generic from '../Generic';

const Input = React.forwardRef(({
  name,
  type,
  label,
  description,
  placeholder,
  defaultValue,
  value,
  validator,
  isDisabled,
  maxLength,
  min,
  max,
  autoComplete,
  autoFocus,
  onChange,
}, ref) => {
  const [error, setError] = useState('');
  const errorRef = useRef('');
  const inputRef = useRef(null);

  const customValidation = async () => {
    if (!inputRef.current) return;
    if (onChange) {
      onChange(inputRef.current.value);
    }
    const res = await validate(inputRef.current.value, validator);
    if (!res.isValid) {
      inputRef.current.setCustomValidity(res.error);
      errorRef.current = res.error;
    } else {
      inputRef.current.setCustomValidity('');
      errorRef.current = '';
      setError('');
    }
  };

  const updateErrorText = () => {
    customValidation();
    setError(errorRef.current);
  };

  useEffect(() => {
    customValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Generic
      error={error}
      label={label}
      name={name}
      validator={validator}
      description={description}
    >
      <div className="Input">
        {type === 'textarea' ? (
          <textarea
            className={`
                Input-field
                ${error ? 'Input-field--hasError' : ''}
              `}
            ref={(node) => {
              inputRef.current = node;
              if (ref) {
                ref.current = node;
              }
            }}
            onInvalid={customValidation}
            onInput={customValidation}
            onBlur={updateErrorText}
            id={name}
            name={name}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            value={value}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={isDisabled}
            maxLength={maxLength}
          />
        ) : (
          <input
            className={`
                Input-field
                ${error ? 'Input-field--hasError' : ''}
              `}
            ref={(node) => {
              inputRef.current = node;
              if (ref) {
                ref.current = node;
              }
            }}
            onInvalid={customValidation}
            onInput={customValidation}
            onBlur={updateErrorText}
            id={name}
            name={name}
            value={value}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            placeholder={placeholder}
            defaultValue={defaultValue}
            maxLength={maxLength}
            type={type}
            disabled={isDisabled}
            min={min}
            max={max}
          />
        )}
      </div>
    </Generic>
  );
});

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validator: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      regex: PropTypes.instanceOf(RegExp),
      error: PropTypes.string,
    }),
  ).isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  isDisabled: false,
  value: undefined,
  maxLength: undefined,
  min: undefined,
  max: undefined,
  defaultValue: undefined,
  description: '',
  autoComplete: 'off',
  autoFocus: false,
  label: '',
  type: 'text',
  placeholder: '',
  onChange: null,
};

export default Input;
