import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SessionContext from '../../contexts/SessionContext';
import Car from '../Car';
import TripConnection from '../TripConnection';
import TransitDetails from '../TransitDetails';
import TransitInput from '../TransitInput';
import SloganSG from '../SloganSG';
import PublicUser from '../PublicUser';
import Passengers from '../Passengers';
import Weekdays from '../Weekdays';
import TimeSlots from '../TimeSlots';
import BookingButtons from '../BookingButtons';
import extrasData from '../../../images/imageData/extrasData';

const TripDetails = ({ detailsType, data, searchOptions }) => {
  const { t } = useTranslation();
  const { sessionData } = useContext(SessionContext);
  const toleranceDistance = 5;
  const istMyTrip = data?.UserGuid === sessionData?.userGuid;

  if (!data) {
    return (
      <div className="TripDetails">
        <div className="TripDetails_Placeholder">
          <SloganSG />
        </div>
      </div>
    );
  }

  return (
    <div className="TripDetails">
      <div className="TripDetails_PublicUser">
        <PublicUser userGuid={data.UserGuid} />
      </div>

      {detailsType === 'search' && !istMyTrip && (
        <>
          <hr className="TripDetails_Separator" />
          <BookingButtons data={data} />
        </>
      )}

      {data.Guid && data.Typ === 'Offer' && istMyTrip && (
        <>
          <hr className="TripDetails_Separator" />
          <div className="TripDetails_Passengers">
            <Passengers tripGuid={data.Guid} tripUserGuid={data.UserGuid} />
          </div>
        </>
      )}
      <hr className="TripDetails_Separator" />
      <div className="TripDetails_Trip">
        <h2>{t('tripdetails.headline')}</h2>
        {!data?.IsDirect &&
          Math.round(parseFloat(data.StartDistance), 2) >=
            toleranceDistance && (
            <TransitDetails
              title={t('tripdetails.start.openv.title')}
              startPoint={searchOptions?.dep}
              endPoint={data.MapsStart}
              arrivalTime={
                data.ArrivalTime
                  ? data.ArrivalTime
                  : data.TimeSlots[0].departureTime
              }
            />
          )}

        <TripConnection
          entry={data}
          searchStart={searchOptions?.dep}
          searchDest={searchOptions?.dest}
          showWaypoints
        />
        {!data?.IsDirect &&
          Math.round(parseFloat(data.EndDistance), 2) >= toleranceDistance && (
            <div>
              <TransitDetails
                title={t('tripdetails.dest.openv.title')}
                startPoint={data.MapsDestination}
                endPoint={searchOptions.dest}
                departureTime={
                  data.ArrivalTime
                    ? data.ArrivalTime
                    : data.TimeSlots[0].arrivalTime
                }
              />
              <br />
            </div>
          )}
        {data.Weekdays && <Weekdays data={data} />}
        {data.TimeSlots && data.TimeSlots.length > 0 && (
          <>
            <br />
            <TimeSlots data={data} />
          </>
        )}
        {data?.IsDirect && data.Typ !== 'Request' && (
          <>
            <br />
            <hr className="TripDetails_Separator" />
            <br />
            <TransitInput
              startPoint={searchOptions?.dep}
              arrivalTime={
                data.DepartureTime
                  ? data.DepartureTime
                  : data.TimeSlots[0].departureTime
              }
            />
          </>
        )}
      </div>
      {data.Typ === 'Offer' && (
        <>
          <hr className="TripDetails_Separator" />
          <div className="TripDetails_Car">
            <Car
              car={{
                CarType: data.CarType,
                FreeSeats: data.FreeSeats,
                Fuel: data.Fuel,
                Luggage: data.Luggage,
                Extras: data.Extras,
              }}
            />
          </div>
        </>
      )}
      {data.Typ === 'Request' && (
        <>
          <hr className="TripDetails_Separator" />
          <div className="TripDetails_Car">
            {t('newSearch.luggage_label')}{' '}
            {data.Luggage
              ? t(`offerDetails.luggage.options.${data.Luggage}`)
              : 'Keins'}
            <br />
            {data.FreeSeats ? (
              <>
                {t('newSearch.free_places_label')} {data.FreeSeats}
              </>
            ) : (
              ''
            )}
            <br />
            {data.Extras && (
              <tr>
                <td>{t('fields.car.extras.label')}: </td>
                <td>
                  {data.Extras.selectedOptions?.map((extra) => (
                    <span key={extra}>
                      {`${t(`fields.car.extras.options.${extra}`)}:  ${
                        data.Extras.extrasNumber[extra]
                      }  `}
                    </span>
                  ))}
                </td>
              </tr>
            )}
          </div>
        </>
      )}
      {data.Remarks && (
        <>
          <hr className="TripDetails_Separator" />
          <div className="TripDetails_Remarks">
            <h2>Bemerkungen:</h2>
            <p>{`${data.Remarks.replace(/<br \/>/g, '\n')}`}</p>
          </div>
        </>
      )}
    </div>
  );
};

TripDetails.propTypes = {
  data: PropTypes.shape({
    Guid: PropTypes.string.isRequired,
    Typ: PropTypes.string.isRequired,
    CarType: PropTypes.string,
    CycleOptions: PropTypes.string,
    FreeSeats: PropTypes.string,
    Fuel: PropTypes.string,
    Company: PropTypes.string,
    Gender: PropTypes.string,
    Luggage: PropTypes.string,
    Radius: PropTypes.string,
    Remarks: PropTypes.string,
    ArrivalTime: PropTypes.string,
    Date: PropTypes.string,
    DepartureTime: PropTypes.string,
    Title: PropTypes.string,
    WeekdayCycle: PropTypes.string,
    ProfileImage: PropTypes.string,
    Weekdays: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
    ArrivalTimeDifference: PropTypes.number,
    IsDirect: PropTypes.string,
    StartDistance: PropTypes.number,
    EndDistance: PropTypes.number,
    StartLatitude: PropTypes.string,
    StartLongitude: PropTypes.string,
    EndLatitude: PropTypes.string,
    EndLongitude: PropTypes.string,
    Points: PropTypes.number,
    UserGuid: PropTypes.string,
    Firstname: PropTypes.string,
    Lastname: PropTypes.string,
    Username: PropTypes.string,
    Sex: PropTypes.string,
    Conversations: PropTypes.string,
    Smoking: PropTypes.string,
    Music: PropTypes.string,
    Birthdate: PropTypes.string,
    MapsStart: PropTypes.shape({
      Location: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    MapsDestination: PropTypes.shape({
      Location: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    TimeSlots: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string,
        departureTime: PropTypes.string,
        arrivalTime: PropTypes.string,
      }),
    ).isRequired,
    MapsWaypoints: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string,
        stopover: PropTypes.bool,
      }),
    ).isRequired,
    Extras: PropTypes.shape({
      selectedOptions: PropTypes.arrayOf(PropTypes.string),
      extrasNumber: PropTypes.shape(extrasData.extrasOptions),
    }),
  }),
  searchOptions: PropTypes.shape({
    /** Departure: dep "Street Number, City, Country" */
    dep: PropTypes.string,
    /** Destination: dest  "Street Number, City, Country" */
    dest: PropTypes.string,
    /** Departure Geo Coordinates: depg "Longitude,Latitude" */
    depg: PropTypes.string,
    /** Destination Geo Coordinates: destg "Longitude,Latitude" */
    destg: PropTypes.string,
    /** Week Days: days "0" for Sunday and "6" for Saturday (Mo. - Fr. => "12345") */
    days: PropTypes.string,
    /** Departure Date: depdate "DD.MM.YYYY" */
    depdate: PropTypes.string,
    /** Departure Time: depdate "hh:mm" */
    deptime: PropTypes.string,
    /** Radius in km: radius "5" */
    radius: PropTypes.string,
    /** Type of search: regular or single */
    type: PropTypes.string,
  }),
  detailsType: PropTypes.oneOf(['search', 'dashboard']).isRequired,
};

TripDetails.defaultProps = {
  data: undefined,
  searchOptions: undefined,
};

export default TripDetails;
