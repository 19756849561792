import reqwest from 'reqwest';
import DataContainer from '../logic/dataContainer';
import settings from '../settings';

const getUser = (guid) => {
  return new Promise((resolve, reject) => {
    reqwest({
      url: settings.user.getPublic,
      method: 'GET',
      data: {
        Guid: guid,
      },
    })
      .then((response) => {
        resolve(new DataContainer(response));
      })
      .fail((err) => {
        reject(err);
      });
  });
};

export default getUser;
