import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle } from '@fortawesome/pro-regular-svg-icons';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import useDerivative from '../../hooks/useDerivative';
import settings from '../../settings';
import Loader from '../../components/Loader';
import Page from '../../components/Layout/Page';
import Button from '../../components/Buttons/Button';
import usePageTitle from '../../hooks/usePageTitle';
import SessionContext from '../../contexts/SessionContext';

const DerivativeSelector = () => {
  usePageTitle('Derivatives');
  const currentDerivative = useDerivative();
  const [derivativeList, setDerivativeList] = useState([]);
  const { sessionData } = useContext(SessionContext);

  useEffect(() => {
    reqwest({
      method: 'GET',
      url: settings.general.derivateList,
    })
      .then((result) => {
        if (result) {
          if (result) {
            setDerivativeList(result);
          }
        }
      })
      .fail(() => {});
  }, []);

  const handelSelect = (selectedDerivative) => {
    if (selectedDerivative.className) {
      reqwest({
        method: 'GET',
        url: `${settings.general.specificDerivate}?className=${selectedDerivative.className}`,
      })
        .then((result) => {
          if (result) {
            localStorage.setItem('derivative', JSON.stringify(result));
            location.reload();
          }
        })
        .fail(() => {});
    }
  };

  const checkSignalR = () => {
    if (!sessionData?.userGuid)
      return swal({
        title: 'Sie müssen eingeloggt sein',
        text: 'Bitte loggen Sie sich ein',
        icon: 'error',
      });

    return swal({
      title: 'SignalR',
      text: 'SignalR Testen mit dein UserGuid',
      type: 'info',
      buttons: ['Abbrechen', 'Bestätigen'],
      icon: 'info',
    }).then((confirm) => {
      if (confirm) {
        fetch(
          `${settings.achievements.testSignalR}?UserGuid=${sessionData?.userGuid}&Message=PointsEarned`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionData?.token}`,
            },
          },
        )
          .then((response) => {
            if (response.ok) {
              swal({
                title: 'SignalR Test erfolgreich',
                text: 'SignalR Test war erfolgreich',
                icon: 'success',
              });
            }
          })
          .catch((_error) =>
            swal({
              title: 'SignalR Test fehlgeschlagen',
              text: 'SignalR Test war nicht erfolgreich',
              icon: 'error',
            }),
          );
      }
    });
  };

  if (!derivativeList.length) {
    return (
      <div className="DerivativeSelector">
        <Loader />
      </div>
    );
  }

  return (
    <div className="DerivativeSelector">
      <Page>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginBottom: '20px',
          }}
        >
          <Button onClick={checkSignalR}> SignalR Testen </Button>
        </div>
        <h2>Derivative selector</h2>
        <p>NODE_ENV: {process.env.NODE_ENV}</p>
        <h4>Verfügbare Instanzen:</h4>
        <table className="styled-table">
          <thead>
            <tr>
              <th>selected</th>
              <th>Logo</th>
              <th>Portal Name (displayName)</th>
              <th>URL</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {derivativeList.map((d) => {
              return (
                <tr
                  key={d.className}
                  className={
                    currentDerivative.className === d.className
                      ? 'derivative selected'
                      : 'derivative'
                  }
                  onClick={() => {
                    handelSelect(d);
                  }}
                  role="button"
                >
                  <th>
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={
                          currentDerivative.className === d.className
                            ? faCheck
                            : faCircle
                        }
                      />
                    </div>
                  </th>
                  <th className="logo-th">
                    {d.logo ? (
                      <>
                        <img
                          alt=""
                          src={`${settings.cdn}/pp40/logo.svg`}
                          loading="lazy"
                        />{' '}
                        <img alt="" src={d.logo} loading="lazy" />
                      </>
                    ) : (
                      <img
                        alt=""
                        src={`${settings.cdn}/${d.className}/logo.svg`}
                        loading="lazy"
                      />
                    )}
                  </th>
                  <th>{d.displayName}</th>
                  <th>
                    <a href={d.urls[0]} target="_blank" rel="noreferrer">
                      {d.urls[0]}
                    </a>
                  </th>
                  <th>{d.status}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button to="/">zur Statseite</Button>
      </Page>
    </div>
  );
};

export default DerivativeSelector;
