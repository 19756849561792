import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import reqwest from 'reqwest';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import settings from '../../settings';
import DataContainer from '../../logic/dataContainer';
import Button from '../../components/Buttons/Button';
import Page from '../../components/Layout/Page';
import RideDetail from '../../components/RideDetail';
import DashboardLinksMenu from '../../components/Dashboard/DashboardLinksMenu';
import Loader from '../../components/Loader';
import Card from '../../components/Card';
import SessionContext from '../../contexts/SessionContext';
import usePageTitle from '../../hooks/usePageTitle';

const Requests = (props) => {
  const { t } = useTranslation();
  usePageTitle(t('requestsPage.title'));
  const option = props.match.params.guid;
  const { sessionData } = useContext(SessionContext);
  const [listLoading, setListLoading] = useState(true);
  const [tripList, setTripList] = useState();
  const [selectedTripGuid, seSelectedTripGuid] = useState('');

  const tripSelected = (guid) => {
    seSelectedTripGuid(guid);
    window.scroll({
      top: 400,
      left: 0,
      behavior: 'smooth',
    });
  };

  // Load Trips (request)
  useEffect(() => {
    reqwest({
      method: 'GET',
      url: settings.request.getList,
      data: {
        UserGuid: sessionData?.userGuid,
        Token: sessionData?.token,
      },
    })
      .then((listResponse) => {
        const filteredTripList = new DataContainer(listResponse)
          .getMany('Entry')
          .filter((c) => c.Guid)
          .sort((a, b) => (a.SortDay < b.SortDay ? 1 : -1));
        setTripList(filteredTripList);
        setListLoading(false);
        if (filteredTripList.length) {
          if (
            option === '' ||
            option === undefined ||
            option === null ||
            option === 'requests'
          ) {
            seSelectedTripGuid(filteredTripList[0].Guid);
          } else {
            tripSelected(option);
          }
        }
      })
      .fail((_) => {
        setListLoading(false);
      });
  }, []);

  return (
    <div className="Requests">
      <Page className="ContentContainer--requests">
        <DashboardLinksMenu />
        <br />
        {listLoading ? (
          <Loader />
        ) : (
          <div
            className={`ContentSection-split ${
              selectedTripGuid ? 'hideLeft' : 'hideRight'
            }`}
          >
            <div className="ContentSection-left">
              <h2 className="underline">{t('requestsPage.title')}</h2>

              {tripList?.map((entry) => {
                return (
                  <Card
                    entry={entry}
                    onClick={tripSelected}
                    active={selectedTripGuid === entry.Guid}
                  />
                );
              })}
              <br />
              <Button align="center" to="/request">
                {t('requestsPage.buttons.new')}
              </Button>
            </div>

            <div className="ContentSection-right">
              {selectedTripGuid && (
                <Button
                  className="Back-Button"
                  shape="default"
                  onClick={() => seSelectedTripGuid('')}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />{' '}
                  {t('requestsPage.buttons.backMobile')}
                </Button>
              )}
              <RideDetail tripGuid={selectedTripGuid} type="Request" />
            </div>
          </div>
        )}
      </Page>
    </div>
  );
};

Requests.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      guid: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

Requests.defaultProps = {};

export default Requests;
