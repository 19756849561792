import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import { useTranslation } from 'react-i18next';
import DetailsBottom from '../DetailsBottom';
import settings from '../../settings';
import RatedStars from '../shared/RatedStars';
import Input from '../Inputs/Input';
import Button from '../Buttons/Button';
import DataContainer from '../../logic/dataContainer';
import SessionContext from '../../contexts/SessionContext';
import PublicUser from '../PublicUser';
import Form from '../Inputs/Form';

const UnratedRatingDetail = ({ rating }) => {
  const { t } = useTranslation();
  const { sessionData } = useContext(SessionContext);

  const ratingDataContainerRef = useRef(
    new DataContainer([
      {
        ParamName: 'Booking',
        Params: {
          Guid: rating?.Guid,
          RatingUser: 0,
          RatingUserMessage: '',
          HasUserRated: 'true',
        },
      },
    ]),
  );

  const send = () => {
    if (ratingDataContainerRef.current.get('Booking', 'RatingUser') <= 0) {
      swal({
        title: t('dashboard.ratings.messages.required.title'),
        text: t('dashboard.ratings.messages.required.description'),
        icon: 'error',
      });
      return;
    }

    const bookingContainer = ratingDataContainerRef.current.getStringified();
    reqwest({
      method: 'POST',
      url: settings.booking.update,
      data: {
        Token: sessionData?.token,
        Booking: bookingContainer,
      },
    })
      .then(() => {
        swal({
          title: t('dashboard.ratings.messages.success.title'),
          text: t('dashboard.ratings.messages.success.description'),
          icon: 'success',
        }).then(() => {
          window.location.reload();
        });
      })
      .fail(() => {
        swal({
          title: t('dashboard.ratings.messages.error.title'),
          text: t('dashboard.ratings.messages.error.description'),
          icon: 'error',
        });
      });
  };

  if (!rating) {
    return (
      <div className="UnratedRatingDetail">
        <div className="UnratedRatingDetail-Content">
          <div className="UnratedRatingDetail-userdata" />
        </div>
        <div className="UnratedRatingDetail-footer">
          <DetailsBottom />
        </div>
      </div>
    );
  }

  return (
    <div className="UnratedRatingDetail">
      <div className="UnratedRatingDetail-Content">
        <div className="UnratedRatingDetail-userdata">
          <PublicUser userGuid={rating.TripUserGuid} />
        </div>
        <hr className="UnratedRatingDetail_Separator" />
        <div className="UnratedRatingDetail-message_container">
          <h3 className="UnratedRatingDetail-unrated_heading">
            {t('dashboard.ratings.rating_feedback_heading')}
          </h3>
          <Form onSubmit={send}>
            <RatedStars
              onChange={ratingDataContainerRef.current.set(
                'Booking',
                'RatingUser',
              )}
              validator={[{ required: true }]}
            />
            <Input
              type="textarea"
              maxLength={300}
              name="rating_feedback"
              label={t('dashboard.ratings.rating_feedback_label')}
              placeholder={t('dashboard.ratings.rating_feedback_placeholder')}
              description={t('dashboard.ratings.rating_feedback_description')}
              validator={[]}
              onChange={ratingDataContainerRef.current.set(
                'Booking',
                'RatingUserMessage',
              )}
              defaultValue={ratingDataContainerRef.current.get(
                'Booking',
                'RatingUserMessage',
              )}
            />
            <Button type="submit">
              {t('dashboard.ratings.rating_button_label')}
            </Button>
          </Form>
        </div>
      </div>
      <div className="UnratedRatingDetail-footer">
        <DetailsBottom />
      </div>
    </div>
  );
};

UnratedRatingDetail.propTypes = {
  rating: PropTypes.instanceOf(DataContainer).isRequired,
};

UnratedRatingDetail.defaultProps = {};

export default UnratedRatingDetail;
