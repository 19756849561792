import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import swal from '@sweetalert/with-react';
import reqwest from 'reqwest';

import CarData from '../../../images/imageData/carData';
import ModalButton from '../Buttons/ModalButton';

import EditCar from '../../modals/Car/Edit';
import Button from '../Buttons/Button';
import settings from '../../settings';

const Car = ({
  car,
  editable = false,
  selectable = false,
  updatable = false,
  onClick = null,
  toggleCarDisplay = null,
  updateCarData = null,
  handleUnselection = null,
  onChange,
}) => {
  const { t } = useTranslation();

  let icon = <CarData.car.Compact />;
  switch (car.CarType) {
    case 'compact_car':
      icon = <CarData.car.Compact />;
      break;
    case 'limousine':
      icon = <CarData.car.Limo />;
      break;
    case 'convertible':
      icon = <CarData.car.Cabrio />;
      break;
    case 'station_wagon':
      icon = <CarData.car.Combi />;
      break;
    case 'suv':
      icon = <CarData.car.Suv />;
      break;
    case 'van':
      icon = <CarData.car.Van />;
      break;
    case 'bus':
      icon = <CarData.car.Bus />;
      break;
    default:
      icon = '';
      break;
  }

  const deleteCar = () => {
    swal({
      title: t('deleteCar.messages.confirm.title'),
      text: t('deleteCar.messages.confirm.description'),
      buttons: [
        t('deleteCar.messages.confirm.buttons.cancel'),
        t('deleteCar.messages.confirm.buttons.confirm'),
      ],
      icon: 'warning',
      dangerMode: 'true',
    }).then((confirmed) => {
      if (confirmed) {
        reqwest({
          method: 'POST',
          url: settings.car.delete,
          data: {
            Guid: car.Guid,
          },
        })
          .then(() => {
            // "No Data" or "unauthorized"
            swal({
              title: t('deleteCar.messages.success.title'),
              text: t('deleteCar.messages.success.description'),
              icon: 'success',
            }).then(() => {
              if (onChange) onChange();
              toggleCarDisplay();
            });
          })
          .fail(() => {
            // Never used only 500 error
            swal({
              title: t('deleteCar.messages.error.title'),
              text: t('deleteCar.messages.error.description'),
              icon: 'error',
            });
          });
      }
    });
  };

  const handleSelect = () => {
    onClick(car.Guid);
  };

  return (
    <div className="Car-wrapper">
      <h3 className="Car-title">{car.Title}</h3>
      <div className="Car">
        {icon && car.CarType && (
          <div className="Car-icon">
            {icon}
            <div className="Car-iconText">
              {t(
                `fields.car.carType.options.${
                  car.CarType ? car.CarType : 'limousine'
                }`,
              )}
            </div>
          </div>
        )}
        <table className="Car-info">
          <tbody>
            {car.LicensePlate && (
              <tr>
                <td>{t('fields.car.licensePlate.label')}:</td>
                <td>{car.LicensePlate}</td>
              </tr>
            )}
            {car.Fuel && (
              <tr>
                <td>{t('fields.car.fuel.label')}:</td>
                <td>{t(`fields.car.fuel.options.${car.Fuel}`)}</td>
              </tr>
            )}
            {car.FreeSeats && (
              <tr>
                <td>{t('fields.car.freeSeats.label')}:</td>
                <td>{car.FreeSeats}</td>
              </tr>
            )}
            {car.Luggage && (
              <tr>
                <td>{t('fields.car.luggage.label')}:</td>
                <td>
                  {car.Luggage
                    ? t(`fields.car.luggage.options.${car.Luggage}`)
                    : 'Keins'}
                </td>
              </tr>
            )}
            {car.Extras && (
              <tr>
                <td>{t('fields.car.extras.label')}:</td>
                <td>
                  {car.Extras.selectedOptions?.map((extra) => (
                    <span key={extra}>
                      {`${t(`fields.car.extras.options.${extra}`)}:  ${
                        car.Extras.extrasNumber[extra]
                      }`}
                      <br />
                    </span>
                  ))}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {editable && (
          <div className="Car-buttons">
            <ModalButton
              align="right"
              render={<EditCar guid={car.Guid} updateCarData={updateCarData} />}
            >
              Bearbeiten
            </ModalButton>
            <Button align="right" shape="warning" onClick={deleteCar}>
              Löschen
            </Button>
          </div>
        )}
        {selectable && (
          <div className="Car-buttons">
            <Button align="right" onClick={handleSelect}>
              Auswählen
            </Button>
          </div>
        )}
        {updatable && (
          <div className="Car-buttons">
            <ModalButton
              align="right"
              render={<EditCar guid={car.Guid} updateCarData={updateCarData} />}
            >
              Bearbeiten
            </ModalButton>
            <Button align="right" shape="warning" onClick={handleUnselection}>
              Löschen
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Car.propTypes = {
  car: PropTypes.shape({
    CarType: PropTypes.string,
    FreeSeats: PropTypes.string,
    Fuel: PropTypes.string,
    Guid: PropTypes.string,
    LicensePlate: PropTypes.string,
    Luggage: PropTypes.string,
    Model: PropTypes.string,
    Title: PropTypes.string,
    Typ: PropTypes.string,
    Type: PropTypes.string,
    UserGuid: PropTypes.string,
    Extras: PropTypes.shape({
      selectedOptions: PropTypes.arrayOf(PropTypes.string),
      extrasNumber: PropTypes.shape({
        babycarriage: PropTypes.number,
        wheelchair: PropTypes.number,
      }),
    }),
  }).isRequired,
  editable: PropTypes.bool,
  selectable: PropTypes.bool,
  updatable: PropTypes.bool,
  onClick: PropTypes.func,
  toggleCarDisplay: PropTypes.func,
  updateCarData: PropTypes.func,
  handleUnselection: PropTypes.func,
  onChange: PropTypes.func,
};

Car.defaultProps = {
  editable: false,
  selectable: false,
  updatable: false,
  onClick: null,
  toggleCarDisplay: null,
  updateCarData: null,
  handleUnselection: null,
  onChange: null,
};

export default Car;
