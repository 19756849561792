import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import useSignalR from '../hooks/useSignalR';
import settings from '../settings';
import SessionContext from './SessionContext';

const PushContext = createContext();

export const PushProvider = ({ children }) => {
  const { sessionData } = useContext(SessionContext);
  const signalRState = useSignalR(
    settings.signalR.getPointPush,
    sessionData?.userGuid,
  );

  return (
    <PushContext.Provider value={signalRState}>{children}</PushContext.Provider>
  );
};

PushProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePush = () => {
  const context = useContext(PushContext);
  if (context === undefined) {
    throw new Error('usePush must be used within a PushProvider');
  }
  return context;
};
