import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { allWeekdayIds } from '../../logic/searchHelper';

const Weekdays = ({ data }) => {
  const { t } = useTranslation();

  const translateWeekdayCycle = (WeekdayCycle) => {
    return {
      Wöchentlich: t('newOffer.cycle.options.weekly'),
      '2-Wöchentlich': t('newOffer.cycle.options.bi_weekly'),
      '3-Wöchentlich': t('newOffer.cycle.options.tri_weekly'),
      '4-Wöchentlich': t('newOffer.cycle.options.four_weekly'),
    }[WeekdayCycle];
  };

  if (data.CycleOptions === 'single') {
    return (
      <div className="Weekdays">
        <div className="Weekdays_Label">{t('weekdays.single.label')}</div>
        <div className="Weekdays_Weekdays">{data.Date}</div>
      </div>
    );
  }

  let weekdayArr = data.Weekdays;
  if (weekdayArr !== '' && !Array.isArray(weekdayArr)) {
    weekdayArr = weekdayArr.replace(/"/g, "'").replace(/'/g, '"');
    weekdayArr = weekdayArr !== '' ? JSON.parse(weekdayArr) : '';
  }

  const wd = allWeekdayIds.map((day) => {
    const active = weekdayArr.find((a) => {
      return day === a.value;
    });
    return (
      <div
        key={day}
        className={
          active ? 'Weekdays_Weekdays_active' : 'Weekdays_Weekdays_inactive'
        }
        aria-label={
          active
            ? t(`searchOptions.weekdays.optionsLabel.${day}`)
            : `{t('searchOptions.text_am')} ${t(
                `searchOptions.weekdays.optionsLabel.${day}`,
              )} {t('searchOptions.text_nicht')}`
        }
      >
        {t(`searchOptions.weekdays.options.${day}`)}
      </div>
    );
  });

  return (
    <div className="Weekdays">
      <div className="Weekdays_Label">
        {translateWeekdayCycle(data.WeekdayCycle)}
      </div>
      <div className="Weekdays_Weekdays">{wd}</div>
    </div>
  );
};

Weekdays.propTypes = {
  data: PropTypes.shape({
    CycleOptions: PropTypes.string.isRequired,
    WeekdayCycle: PropTypes.string,
    Weekdays: PropTypes.oneOf([
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      PropTypes.string,
    ]).isRequired,
    Date: PropTypes.string,
  }),
};

Weekdays.defaultProps = {
  data: undefined,
};

export default Weekdays;
