import Babycarriage from '../icons/extras/Babycarriage.svg';
import Wheelchair from '../icons/extras/Wheelchair.svg';

export default {
  extras: {
    Babycarriage,
    Wheelchair,
  },
  extrasOptions: {
    babycarriageValue: 'babycarriage',
    wheelchairValue: 'wheelchair',
  },
};
