import React from 'react';
import { useTranslation } from 'react-i18next';

const SloganSG = () => {
  const { t } = useTranslation();

  return (
    <div className="SloganSG">
      <h2>{t('search.details_title')}</h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="97.95"
        height="26.5"
        viewBox="0 0 97.95 26.5"
      >
        <path
          data-name="Pfad 666"
          d="M23.26 15.9h74.7l-6.6 10.6h-74.7z"
          fill="#fcad19"
        />
        <path data-name="Pfad 692" d="M6.6 0h74.7l-6.6 10.6H0z" fill="#fff" />
      </svg>
      <p>{t('search.details_info_body_p1')}</p>
      <p>{t('search.details_info_body_p2')}</p>
      <p>{t('search.details_info_body_p3')}</p>
    </div>
  );
};

export default SloganSG;
