import Compact from '../icons/car/Kompaktklasse.svg';
import Cabrio from '../icons/car/Cabrio.svg';
import Combi from '../icons/car/Kombi.svg';
import Limo from '../icons/car/Limousine.svg';
import Van from '../icons/car/Minivan.svg';
import Suv from '../icons/car/SUV.svg';
import Bus from '../icons/car/Bus.svg';

export default {
  car: {
    Suv,
    Compact,
    Combi,
    Limo,
    Van,
    Cabrio,
    Bus,
  },
};
