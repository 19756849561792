import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSmile, faUser } from '@fortawesome/pro-regular-svg-icons';
import {
  handleMusic,
  handleConversations,
  handleSmoking,
  handleCompany,
  handleSex,
} from '../../constants/profileValues';
import getUser from '../../actions/getUser';
import useDerivative from '../../hooks/useDerivative';
import ProfileImage from '../ProfileImage';
import UserRating from '../shared/UserRating';
import RenderHTML from '../RenderHTML';

const PublicUser = ({ userGuid }) => {
  const { t } = useTranslation();
  const { companies } = useDerivative();
  const [userDataContainer, setUserDataContainer] = useState(null);
  useEffect(() => {
    getUser(userGuid).then((publicUser) => {
      setUserDataContainer(publicUser);
    });
  }, [userGuid]);

  if (!userGuid || !userDataContainer) {
    return <div className="PublicUser" />;
  }

  const genderValues = handleSex(userDataContainer.get('PersonalInfo', 'Sex'));
  const smokingValues = handleSmoking(
    userDataContainer.get('PersonalInfo', 'Smoking'),
  );
  const conversationsValues = handleConversations(
    userDataContainer.get('PersonalInfo', 'Conversations'),
  );
  const musicValues = handleMusic(
    userDataContainer.get('PersonalInfo', 'Music'),
  );
  const companyValues = handleCompany(
    userDataContainer.get('PersonalInfo', 'Company'),
    companies,
  );

  return (
    <div className="PublicUser">
      <div className="ProfileImage-Wrapper">
        <ProfileImage
          guid={userDataContainer.get('PersonalInfo', 'ProfileImage')}
        />
      </div>
      <div className="PublicUser-content">
        <div className="PublicUser-user">
          <h2 className="PublicUser-name">
            {userDataContainer.get('RequiredData', 'Username')}
          </h2>
          <UserRating guid={userDataContainer.get('Basic', 'Guid')} />
        </div>

        <div className="PublicUser-preferences">
          {companyValues && (
            <div className="PublicUser-preference">
              <companyValues.icon className="PublicUser-preference-icon" />
              <div className="PublicUser-preference-text">
                {companyValues.value}
              </div>
            </div>
          )}
          <div className="PublicUser-preference">
            {genderValues.icon && (
              <FontAwesomeIcon
                icon={genderValues.icon}
                className="PublicUser-preference-icon"
              />
            )}
            <div className="PublicUser-preference-text">
              {genderValues.value}
            </div>
          </div>
          <div className="PublicUser-preference">
            <smokingValues.icon className="PublicUser-preference-icon" />
            <div className="PublicUser-preference-text">
              {smokingValues.value}
            </div>
          </div>
          <div className="PublicUser-preference">
            <conversationsValues.icon className="PublicUser-preference-icon" />
            <div className="PublicUser-preference-text">
              {conversationsValues.value}
            </div>
          </div>
          <div className="PublicUser-preference">
            <musicValues.icon className="PublicUser-preference-icon" />
            <div className="PublicUser-preference-text">
              {musicValues.value}
            </div>
          </div>

          {userDataContainer.get('PersonalInfo', 'About') && (
            <div className="PublicUser-preference">
              <FontAwesomeIcon
                icon={faUser}
                className="PublicUser-preference-icon"
              />
              <div className="PublicUser-preference-text">
                {t('publicuser.preference.text')}{' '}
                <RenderHTML
                  html={userDataContainer.get('PersonalInfo', 'About')}
                />
              </div>
            </div>
          )}
          {userDataContainer.get('PersonalInfo', 'FavoriteTopics') && (
            <div className="PublicUser-preference">
              <FontAwesomeIcon
                icon={faCommentSmile}
                className="PublicUser-preference-icon"
              />
              <div className="PublicUser-preference-text">
                {t('publicuser.preference.favoritetopics.text')}{' '}
                <RenderHTML
                  html={userDataContainer.get('PersonalInfo', 'FavoriteTopics')}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PublicUser.propTypes = {
  userGuid: PropTypes.string.isRequired,
};

PublicUser.defaultProps = {};

export default PublicUser;
