import {
  faMars,
  faTransgenderAlt,
  faVenus,
} from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';
import IconSmokingYes from '../../images/icons/smoking-yes.svg';
import IconSmokingNo from '../../images/icons/smoking-no.svg';
import IconSmokingSometimes from '../../images/icons/smoking-sometimes.svg';
import IconConversationsYes from '../../images/icons/conversations-yes.svg';
import IconConversationsNo from '../../images/icons/conversations-no.svg';
import IconConversationsSometimes from '../../images/icons/conversations-sometimes.svg';
import IconMusicYes from '../../images/icons/music-yes.svg';
import IconMusicNo from '../../images/icons/music-no.svg';
import IconMusicSometimes from '../../images/icons/music-sometimes.svg';
import IconColleague from '../../images/icons/colleague.svg';

const handleSex = (sex) => {
  let value = '';
  let icon = '';
  switch (sex) {
    case 'male':
      value = i18next.t('fields.user.sex.options.male');
      icon = faMars;
      break;
    case 'female':
      value = i18next.t('fields.user.sex.options.female');
      icon = faVenus;
      break;
    case 'nonBinary':
      value = i18next.t('fields.user.sex.options.nonBinary');
      icon = faTransgenderAlt;
      break;
    default:
      value = '';
      icon = undefined;
      break;
  }
  return { value, icon };
};

const handleSmoking = (smoking) => {
  let value = '';
  let icon = '';
  switch (smoking) {
    case 'yes':
      value = i18next.t('fields.user.smoking.options.yes');
      icon = IconSmokingYes;
      break;
    case 'no':
      value = i18next.t('fields.user.smoking.options.no');
      icon = IconSmokingNo;
      break;
    case 'sometimes':
      value = i18next.t('fields.user.smoking.options.sometimes');
      icon = IconSmokingSometimes;
      break;
    default:
      value = i18next.t('fields.user.smoking.options.no');
      icon = IconSmokingNo;
      break;
  }
  return { value, icon };
};

const handleConversations = (conversations) => {
  let value = '';
  let icon = '';
  switch (conversations) {
    case 'yes':
      value = i18next.t('fields.user.conversations.options.yes');
      icon = IconConversationsYes;
      break;
    case 'no':
      value = i18next.t('fields.user.conversations.options.no');
      icon = IconConversationsNo;
      break;
    case 'sometimes':
      value = i18next.t('fields.user.conversations.options.sometimes');
      icon = IconConversationsSometimes;
      break;
    default:
      value = i18next.t('fields.user.conversations.options.yes');
      icon = IconConversationsYes;
      break;
  }
  return { value, icon };
};

const handleMusic = (music) => {
  let value = '';
  let icon = '';
  switch (music) {
    case 'yes':
      value = i18next.t('fields.user.music.options.yes');
      icon = IconMusicYes;
      break;
    case 'no':
      value = i18next.t('fields.user.music.options.no');
      icon = IconMusicNo;
      break;
    case 'sometimes':
      value = i18next.t('fields.user.music.options.sometimes');
      icon = IconMusicSometimes;
      break;
    default:
      value = i18next.t('fields.user.music.options.none');
      icon = IconMusicYes;
      break;
  }
  return { value, icon };
};

const handleCompany = (company, companies) => {
  if (company && companies && companies[company]) {
    return { value: companies[company], icon: IconColleague };
  }
  if (companies) {
    return {
      value: i18next.t('fields.user.companies.options.none'),
      icon: IconColleague,
    };
  }
  return null;
};

export {
  handleSex,
  handleMusic,
  handleConversations,
  handleSmoking,
  handleCompany,
};
