const faviconOverride = (className) => {
  const isFaviconAlreadyLoaded = () => {
    const selectedLinks = document.head.querySelectorAll(
      `link[id="favicon_${className}"]`,
    );
    return selectedLinks.length > 0;
  };

  const createAndAppendLink = () => {
    const link = document.createElement('link');
    link.id = `favicon_${className}`;
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = `https://cdn.pendlerportal.de/${className}/favicon.ico`;
    document.head.appendChild(link);
  };

  if (!isFaviconAlreadyLoaded()) {
    createAndAppendLink();
  }
};
export default faviconOverride;
