import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import reqwest from 'reqwest';
import { useIndexedDB } from 'react-indexed-db';
import defaultImage from '../../../images/illustrations/thumbnail.png';
import settings from '../../settings';
import SessionContext from '../../contexts/SessionContext';

const ProfileImage = ({ guid, big, huge }) => {
  const { sessionData } = useContext(SessionContext);
  const [loading, setLoading] = useState(true);
  const { add, getByIndex } = useIndexedDB('images');
  const [image, setImage] = useState(defaultImage);

  useEffect(() => {
    if (guid) {
      setLoading(true);
      getByIndex('guid', guid)
        .then((imgData) => {
          if (imgData) {
            setImage(imgData.img);
            setLoading(false);
          } else {
            reqwest({
              method: 'GET',
              url: settings.files.get,
              data: {
                Token: sessionData?.token,
                Guid: guid,
              },
            })
              .then((result) => {
                const res = JSON.parse(result);
                if (
                  res.Params &&
                  res.Params.Data &&
                  res.Params.Data.startsWith('data:image')
                ) {
                  setImage(res.Params.Data);
                  add({ guid: res.Params.Guid, img: res.Params.Data });
                } else {
                  setImage(defaultImage);
                }
              })
              .fail(() => {
                setImage(defaultImage);
              })
              .always(() => {
                setLoading(false);
              });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setImage(defaultImage);
      setLoading(false);
    }
  }, [guid]);

  return (
    <div
      className={`ProfileImage ${big && 'ProfileImage--big'} ${
        huge && 'ProfileImage--huge'
      }`}
    >
      {loading ? (
        <div className="ProfileImage-loading">
          <Spinner />
        </div>
      ) : (
        <img src={image} alt="" />
      )}
    </div>
  );
};

ProfileImage.propTypes = {
  guid: PropTypes.string,
  big: PropTypes.bool,
  huge: PropTypes.bool,
};

ProfileImage.defaultProps = {
  guid: '',
  big: false,
  huge: false,
};

export default ProfileImage;

const Spinner = () => {
  return (
    <div className="Spinner">
      <div className="Spinner-bounce1" />
      <div className="Spinner-bounce2" />
    </div>
  );
};

Spinner.propTypes = {};

Spinner.defaultProps = {};
