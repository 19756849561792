import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TimeSlots = ({ data }) => {
  const { t } = useTranslation();

  if (
    !Array.isArray(data.TimeSlots) ||
    data.TimeSlots.length === 0 ||
    !Array.isArray(data.Weekdays)
  ) {
    return <div className="TimeSlots Consistent" />;
  }

  return (
    <div className="TimeSlots Individually">
      {data.Weekdays.map(({ value }) => {
        return (
          <div key={value} className="IndividualTime">
            <div className="WeekDayBox">
              {t(`newOffer.weekdays.options.${value}`)}
            </div>

            <div className="IndividualTimeInputs">
              {data.TimeSlots.find((slot) => slot.day === value)?.departureTime}
              {' - '}
              {data.TimeSlots.find((slot) => slot.day === value)?.arrivalTime}
            </div>
          </div>
        );
      })}
    </div>
  );
};

TimeSlots.propTypes = {
  data: PropTypes.shape({
    TimeSlots: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string,
        departureTime: PropTypes.string,
        arrivalTime: PropTypes.string,
      }),
    ).isRequired,
    Weekdays: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ).isRequired,
  }),
};

TimeSlots.defaultProps = {
  data: undefined,
};

export default TimeSlots;
