const addMatomoScript = (siteId) => {
  if (siteId) {
    const script = document.createElement('script');
    script.text = `
    var _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = '//matomo.marktplatz-agentur.de/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '${siteId}']);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u + 'matomo.js';
      s.parentNode.insertBefore(g, s);
    })();
    // alert('Test matomo SiteId: ${siteId}');
    `;
    document.head.appendChild(script);
  }
};
export default addMatomoScript;
