import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import reqwest from 'reqwest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../settings';
import DataContainer from '../../logic/dataContainer';
import Button from '../../components/Buttons/Button';
import Page from '../../components/Layout/Page';
import DashboardLinksMenu from '../../components/Dashboard/DashboardLinksMenu';
import Card from '../../components/Card';
import RideDetail from '../../components/RideDetail';
import Loader from '../../components/Loader';
import SessionContext from '../../contexts/SessionContext';
import usePageTitle from '../../hooks/usePageTitle';

const Rides = (props) => {
  const { t } = useTranslation();
  usePageTitle(t('ridesPage.title'));
  const option = props.match.params.guid;
  const { sessionData } = useContext(SessionContext);
  const [listLoading, setListLoading] = useState(true);
  const [tripList, setTripList] = useState();
  const [selectedTripGuid, seSelectedTripGuid] = useState('');

  const tripSelected = (guid) => {
    seSelectedTripGuid(guid);
    window.scroll({
      top: 400,
      left: 0,
      behavior: 'smooth',
    });
  };

  // Load Trips (offer list)
  useEffect(() => {
    reqwest({
      method: 'GET',
      url: settings.offer.getList,
      data: {
        Token: sessionData?.token,
        UserGuid: sessionData?.userGuid,
        onlyTripOffers: 'false',
      },
    })
      .then((listResponse) => {
        const filteredTripList = new DataContainer(listResponse)
          .getMany('Entry')
          .filter((c) => c.Guid)
          .sort((a, b) => (a.SortDay < b.SortDay ? 1 : -1));
        setTripList(filteredTripList);
        setListLoading(false);
        if (filteredTripList.length) {
          if (
            option === '' ||
            option === undefined ||
            option === null ||
            option === 'requests'
          ) {
            seSelectedTripGuid(filteredTripList[0].Guid);
          } else {
            tripSelected(option);
          }
        }
      })
      .fail((_) => {
        setListLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Rides">
      <Page className="ContentContainer--rides">
        <DashboardLinksMenu option={option} />
        <br />
        {listLoading ? (
          <Loader />
        ) : (
          <div
            className={`ContentSection-split ${
              selectedTripGuid ? 'hideLeft' : 'hideRight'
            }`}
          >
            <div className="ContentSection-left">
              <h2 className="underline">{t('ridesPage.title')}</h2>
              {tripList?.map((entry) => {
                return (
                  <Card
                    entry={entry}
                    active={selectedTripGuid === entry.Guid}
                    onClick={tripSelected}
                  />
                );
              })}
              <br />
              <Button align="center" to="/offer">
                {t('ridesPage.buttons.new')}
              </Button>
            </div>

            <div className="ContentSection-right">
              {selectedTripGuid && (
                <Button
                  className="Back-Button"
                  shape="default"
                  onClick={() => {
                    seSelectedTripGuid('');
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {t('ridesPage.buttons.backMobile')}
                </Button>
              )}
              <RideDetail tripGuid={selectedTripGuid} type="Offer" />
            </div>
          </div>
        )}
      </Page>
    </div>
  );
};

Rides.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      guid: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

Rides.defaultProps = {};

export default Rides;
