import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import SessionContext from '../../../contexts/SessionContext';

const PrivateRoute = ({
  component: Component,
  children,
  redirect,
  ...filteredProps
}) => {
  const { sessionData } = React.useContext(SessionContext);

  return (
    <Route
      {...filteredProps}
      render={(renderProps) => {
        if (!sessionData && redirect) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: renderProps.location },
              }}
            />
          );
        }
        if (!sessionData) {
          return null;
        }
        if (Component) {
          return <Component {...renderProps} />;
        }
        return children;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]),
  redirect: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  children: null,
  component: null,
  redirect: true,
};

export default PrivateRoute;
