import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useMemo, useState } from 'react';
import deTranslations from '../../lang/de';
// import deChTranslations from '../../lang/de-CH'; // for testing
import enTranslations from '../../lang/en';

export default function useLanguage() {
  const [languageReady, setReady] = useState(false);

  // available languages
  const pl = localStorage.getItem('portal_languages') || '';
  const languages = pl.split(',');

  const setLanguages = (lArray) => {
    localStorage.setItem('portal_languages', lArray.join(','));
  };

  // selected languages
  const [selected, setS] = useState(
    localStorage.getItem('selected_language') || 'de',
  );

  const setSelected = async (l) => {
    if (!languages.includes(l) && l === selected) {
      return;
    }
    i18next.changeLanguage(l);
    setS(l);
    localStorage.setItem('selected_language', l);
    document.documentElement.setAttribute('lang', l);
  };

  // init langauges with i18next
  useMemo(() => {
    const resources = {
      de: deTranslations,
      // 'de-CH': deChTranslations, // for testing
      en: enTranslations,
    };
    const callback = () => {
      setReady(true);
    };
    i18next
      .use(initReactI18next) // passes i18n down to react-i18next
      .init(
        {
          defaultNS: 'translation',
          fallbackNS: 'translation',
          resources,
          lng: selected,
          fallbackLng: 'de',
          interpolation: {
            escapeValue: false, // react already safes from xss
          },
        },
        callback,
      );
  }, []);

  return { selected, setSelected, languages, setLanguages, languageReady };
}
