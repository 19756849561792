import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/Layout/PrivateRoute';
import PointPush from './components/PointPush';
import { PushProvider } from './contexts/PushContext';
import SearchContext from './contexts/SearchContext';
import SearchFilterContext, {
  searchFilterContextDefault,
} from './contexts/SearchFilterContext';
import SessionContext from './contexts/SessionContext';
import useDerivative from './hooks/useDerivative';
import history from './logic/history';
import { useSearchHelper, useWidgetParams } from './logic/searchHelper';
import AboutPP from './routes/AboutPP';
import Achievements from './routes/Achievements';
import Agb from './routes/Agb';
import Contact from './routes/Contact';
import Dashboard from './routes/Dashboard';
import DeleteProfile from './routes/DeleteProfile';
import DerivativeCreator from './routes/DerivativeCreator';
import DerivativeSelector from './routes/DerivativeSelector';
import ForgotPassword from './routes/ForgotPassword';
import Help from './routes/Help';
import HomePage from './routes/HomePage';
import Imprint from './routes/Imprint';
import Login from './routes/Login';
import Logout from './routes/Logout';
import MessageCenter from './routes/MessageCenter';
import NewOffer from './routes/NewOffer';
import NewSearch from './routes/NewSearch';
import Press from './routes/Press';
import Privacy from './routes/Privacy';
import Profile from './routes/Profile';
import ProfileEdit from './routes/ProfileEdit';
import Ratings from './routes/Ratings';
import Requests from './routes/Requests';
import ResetPassword from './routes/ResetPassword';
import Rides from './routes/Rides';
import Search from './routes/Search';
import SignUp from './routes/SignUp';
import VerifyEmail from './routes/VerifyEmail';

const App = () => {
  const currentDerivative = useDerivative();

  const { searchOptions, updateSearchOptions } = useSearchHelper();
  const [filterOptions, setFilterOptions] = useState(
    searchFilterContextDefault,
  );
  const widgetParams = useWidgetParams();

  useEffect(() => {
    if (widgetParams) {
      updateSearchOptions(widgetParams);
      if (widgetParams.depg && widgetParams.destg) history.push('/search');
    }
  }, [widgetParams]);

  return (
    <SearchFilterContext.Provider value={{ filterOptions, setFilterOptions }}>
      <PushProvider>
        {currentDerivative.achievements && <PointPush />}
        <SearchContext.Provider value={{ searchOptions, updateSearchOptions }}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            {currentDerivative.loginTypes &&
            currentDerivative.loginTypes.forceSSO ? (
              <Route path="/sign-up" component={Login} />
            ) : (
              <Route path="/sign-up" component={SignUp} />
            )}
            <Route path="/login" component={Login} />
            <Route path="/delete" component={DeleteProfile} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route path="/verify-email/:token" component={VerifyEmail} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/logout" component={Logout} />
            <PrivateRoute path="/profile-edit" component={ProfileEdit} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute
              path="/dashboard/ratings/:guid?"
              component={Ratings}
            />
            <PrivateRoute path="/dashboard/rides/:guid?" component={Rides} />
            <PrivateRoute
              path="/dashboard/requests/:guid?"
              component={Requests}
            />
            <Route path="/search" component={Search} />
            <PrivateRoute path="/offer/:option?" component={NewOffer} />
            <PrivateRoute path="/request/:option?" component={NewSearch} />
            <PrivateRoute
              path="/dashboard/message-center"
              component={MessageCenter}
            />
            {currentDerivative.achievements && (
              <Route path="/dashboard/achievements" component={Achievements} />
            )}
            <Route path="/search/:origin?/:destination?" component={Search} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/imprint" component={Imprint} />
            <Route path="/help" component={Help} />
            <Route path="/about" component={AboutPP} />
            <Route path="/agb" component={Agb} />
            <Route path="/press" component={Press} />
            <Route path="/contact" component={Contact} />
            {process.env.NODE_ENV === 'development' && (
              <>
                <Route path="/derivatives" component={DerivativeSelector} />
                <Route path="/newderivatives" component={DerivativeCreator} />
              </>
            )}
          </Switch>
        </SearchContext.Provider>
      </PushProvider>
    </SearchFilterContext.Provider>
  );
};

export default App;
