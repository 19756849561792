import React, { useContext } from 'react';
import reqwest from 'reqwest';
import SessionContext from '../contexts/SessionContext';

function deepParseJson(jsonString) {
  // if not stringified json rather a simple string value then JSON.parse will throw error
  // otherwise continue recursion
  if (typeof jsonString === 'string') {
    if (!isNaN(Number(jsonString))) {
      // if a numeric string is received, return itself
      // otherwise JSON.parse will convert it to a number
      return jsonString;
    }
    try {
      return deepParseJson(JSON.parse(jsonString));
    } catch (err) {
      return jsonString;
    }
  } else if (Array.isArray(jsonString)) {
    // if an array is received, map over the array and deepParse each value
    return jsonString.map((val) => {
      return deepParseJson(val);
    });
  } else if (typeof jsonString === 'object' && jsonString !== null) {
    // if an object is received then deepParse each element in the object
    // typeof null returns 'object' too, so we have to eliminate that
    return Object.keys(jsonString).reduce((obj, key) => {
      const newObject = { ...obj };
      newObject[key] = deepParseJson(jsonString[key]);
      return newObject;
    }, {});
  } else {
    // otherwise return whatever was received
    return jsonString;
  }
}

class DataContainer {
  constructor(jsonContainer = '[]') {
    this.containers = deepParseJson(jsonContainer);
    this.initialContainers = deepParseJson(jsonContainer);
    if (typeof this.containers !== 'object') {
      this.containers = [];
      this.initialContainers = [];
    }
    this.changeListeners = [];
  }

  _get = (containerToUse, containerName, ...path) => {
    if (containerName) {
      const container = containerToUse.find((c) => {
        return c.ParamName === containerName;
      });

      if (!container) return undefined;

      let value = container.Params;
      path.forEach((segment) => {
        if (value && segment in value) {
          value = value[segment];
        } else {
          value = undefined;
        }
      });
      return value;
    }
    return containerToUse;
  };

  get(containerName, ...path) {
    return this._get(this.containers, containerName, ...path);
  }

  getInitial(containerName, ...path) {
    return this._get(this.initialContainers, containerName, ...path);
  }

  updateInitalContainers() {
    this.initialContainers = this.containers;
  }

  getMany(containerName) {
    if (containerName) {
      const containers = this.containers.filter((container) => {
        return container.ParamName === containerName;
      });

      if (containers && containers.length) {
        return containers.map((container) => {
          return container.Params;
        });
      }
    } else if (this.containers && this.containers.length) {
      return this.containers.map((container) => {
        return container.Params;
      });
    }
    return [];
  }

  getStringified() {
    const containers = this.containers.map((container) => {
      const Params = {};
      if (container.Params && Object.keys(container.Params).length) {
        Object.keys(container.Params).forEach((paramKey) => {
          const value = container.Params[paramKey];
          if (typeof value === 'string') {
            Params[paramKey] = value;
          } else {
            Params[paramKey] = JSON.stringify(value);
          }
        });
      }
      return {
        ...container,
        Params,
      };
    });
    return JSON.stringify(containers);
  }

  set(containerName, ...pathArray) {
    if (containerName) {
      let container = this.containers.find((c) => {
        return c.ParamName === containerName;
      })?.Params;

      if (!container) {
        /* eslint-disable */
        console.info(
          `Couldn't find container with name ${containerName}. Creating it...`,
        );
        /* eslint-enable */
        this.add(containerName);

        container = this.containers.find((c) => {
          return c.ParamName === containerName;
        })?.Params;
      }

      const setNestedKey = (obj, path, value) => {
        if (path.length === 1) {
          obj[path] = value;
          this.changeListeners.forEach((callback) => {
            callback();
          });
          return;
        }
        setNestedKey(obj[path[0]], path.slice(1), value);
      };

      return (value) => {
        setNestedKey(container, pathArray, value);
      };
    }
    return null;
  }

  add(containerName, params = {}) {
    this.containers.push({
      ParamName: containerName,
      Params: params,
    });
  }

  onChange(callback) {
    this.changeListeners.push(callback);
  }
}

const useDataContainer = ({ url = '', method = 'GET', data = {} }) => {
  const { sessionData } = useContext(SessionContext);
  const [dataContainer, setDataContainer] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [r, setReload] = React.useState(0);

  const dataRef = React.useRef(data);

  React.useEffect(() => {
    dataRef.current = data;
  }, [data]);

  React.useEffect(() => {
    if (url) {
      reqwest({
        method,
        url,
        data: {
          Token: sessionData?.token,
          ...dataRef.current,
        },
      })
        .then((response) => {
          try {
            setDataContainer(new DataContainer(response));
          } catch (e) {
            setDataContainer(null);
            setError(e.message);
          }
        })
        .catch((response) => {
          setError(response);
        })
        .always(() => {
          setLoading(false);
        });
    } else {
      const temp = new DataContainer();
      setDataContainer(temp);
      setLoading(false);
    }
  }, [url, method, dataRef, r]);

  const reload = () => {
    setReload(Math.random());
  };

  return { dataContainer, loading, error, reload };
};

export { useDataContainer };

export default DataContainer;
