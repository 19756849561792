const settings = {};

const URL = `${window.location.origin}/api`;

settings.cdn = 'https://cdn.pendlerportal.de';

settings.appGuid = '4711';

settings.general = {
  derivateSettings: `${URL}/Home/DerivateSettings`,
  specificDerivate: `${URL}/Home/SpecificDerivate`, // param className: string
  derivateList: `${URL}/Home/DerivateList`,
  login: `${URL}/Home/Login`,
  evonikLogin: `${URL}/Home/EvonikLogin`,
  googleLogin: `${URL}/Home/GoogleLogin`,
  sessionUser: `${URL}/Home/SessionUser`,
  checkToken: `${URL}/Home/CheckToken`,
  logout: `${URL}/Home/Logout`,
};

settings.preview = {
  specificDerivate:
    'https://conf.pendlerportal.de/api/PPConfig/SpecificDerivate',
  derivateList: 'https://conf.pendlerportal.de/api/PPConfig/DerivateList',
};

settings.statisticData = {
  publicData: `${URL}/statisticdata/publicdata`,
};

settings.achievements = {
  getPointsForUser: `${URL}/Achievement/GetPointsForUser`,
  testSignalR: `${URL}/Achievement/TestSignalR`,
};

settings.signalR = {
  getPointPush: `${window.location.origin}/achievementHub`,
};

settings.validators = {
  email: `${URL}/Validate/ValidateEmail`,
  username: `${URL}/Validate/ValidateUsername`,
  code: `${URL}/Validate/ValidateCode`,
  staffnumber: `${URL}/Validate/ValidateStaffnumber`,
  staffnumberbirthdate: `${URL}/Validate/ValidateStaffnumberBirthdate`,
};

settings.files = {
  /**
   * @method GET
   * @param Guid string
   */
  get: `${URL}/File/GetFile`,
  /**
   * @method POST
   * @param Files DataContainer
   */
  save: `${URL}/File/SaveFile`,
};

settings.user = {
  /**
   * @method POST
   * @param User DataContainer
   */
  signUp: `${URL}/User/SaveUser`, // incl. language prop
  /**
   * @method POST
   * @param User DataContainer
   */
  save: `${URL}/User/SaveUserAccount`,
  /**
   * @method POST
   * @param Guid string
   */
  delete: `${URL}/User/DeleteUser`,
  /**
   * @method GET
   * @param Guid string
   */
  get: `${URL}/User/GetUser`,
  /**
   * Returns subset of the User:
   * [
   *  {
   *      "ParamName": "Basic",
   *      "Params": {
   *        "Guid": "ad8504e0-4b59-4278-a211-cd530356736f",
   *        "Typ": "User"
   *      }
   *    },
   *    {
   *      "ParamName": "RequiredData",
   *      "Params": {
   *        "Username": "Username"
   *      }
   *    },
   *    {
   *      "ParamName": "PersonalInfo",
   *      "Params": {
   *        "Conversations": "",
   *        "FavoriteTopics": "",
   *        "Smoking": "",
   *        "Music": "",
   *        "About": "",
   *        "ProfileImage": "e9d269c9-42e6-4806-8389-9e4b1f52aa18"
   *      }
   *    }
   *  ]
   * @method GET
   * @param Guid string
   */
  getPublic: `${URL}/User/GetPublicUser`,
  /**
   * @method GET
   */
  // getList: `${URL}/User/GetUserList`,
  /**
   * @method GET
   * @param Token string
   */
  activate: `${URL}/User/ActivateUser`,
  /**
   * @method POST
   * @param Email string
   */
  newVerification: `${URL}/User/SendNewVerification`, // incl. language prop
};

settings.car = {
  /**
   * @method POST
   * @param Car DataContainer
   */
  save: `${URL}/User/SaveCar`,
  /**
   * @method POST
   * @param Guid string
   */
  delete: `${URL}/User/DeleteCar`,
  /**
   * @method GET
   * @param Guid string
   */
  get: `${URL}/User/GetCar`,
  /**
   * @method GET
   * @param UserGuid string
   */
  getList: `${URL}/User/GetCarList`,
};

settings.address = {
  /**
   * @method POST
   * @param Address DataContainer
   */
  save: `${URL}/User/SaveAddress`,
  /**
   * @method POST
   * @param Guid string
   */
  delete: `${URL}/User/DeleteAddress`,
  /**
   * @method GET
   * @param Guid string
   */
  get: `${URL}/User/GetAddress`,
  /**
   * @method GET
   * @param UserGuid string
   */
  getList: `${URL}/User/GetAddressList`,
};

settings.contact = {
  /**
   * @method POST
   * @param Contact DataContainer
   */
  save: `${URL}/Contact/Submit`,
};

settings.forgot = {
  new: `${URL}/Password/ForgotPassword`, // incl. language prop
  check: `${URL}/Password/CheckPasswordToken`,
  save: `${URL}/Password/SaveNewPassword`,
};

settings.offer = {
  /**
   * @method POST
   * @param  Trip DataContainer serialized JSON
   */
  save: `${URL}/Travel/AddTripOffer`,
  get: `${URL}/Travel/GetTripOffer`,
  getList: `${URL}/Travel/GetTripOfferList`,
  update: `${URL}/Travel/UpdateTripOffer`,
  delete: `${URL}/Travel/DeleteTripOffer`,
};

settings.request = {
  save: `${URL}/Travel/AddTripRequest`,
  get: `${URL}/Travel/GetTripRequest`,
  getList: `${URL}/Travel/GetTripRequestList`,
  update: `${URL}/Travel/UpdateTripRequest`,
  delete: `${URL}/Travel/DeleteTripRequest`,
};

settings.booking = {
  save: `${URL}/Booking/AddBooking`,
  update: `${URL}/Booking/UpdateBooking`,
  getAllBookingsForParameter: `${URL}/Booking/GetAllBookingsForParameter`,
  getAverageRating: `${URL}/Booking/GetAverageRatingForUserGuid`,
  getUnratedBookings: `${URL}/Booking/GetUnratedBookingsForUserGuid`,
  getRatedBookings: `${URL}/Booking/GetRatedBookingsForUserGuid`,
  getAllUnaccepted: `${URL}/Booking/GetAllUnacceptedBookingsForUserGuid`,
};

settings.search = {
  singleRequest: `${URL}/Search/SearchTrip`,
  cycleRequest: `${URL}/Search/SearchTripCycle`,
  oepnvSingleRequest: `${URL}/Search/SearchTripOepnv`,
  oepnvCycleRequest: `${URL}/Search/SearchTripCycleOepnv`,
};

settings.message = {
  /**
   * @method POST
   * @param  Message DataContainer serialized JSON with empty Guid
   */
  addMessage: `${URL}/Message/AddMessage`,
  /**
   * Use this to send multiple messages in a DataContainer for one Communication.
   * Missing CommunicationGuid will all be the same!
   * @method POST
   * @param  messageList DataContainer serialized JSON with empty Guid
   */
  addMessageList: `${URL}/Message/AddMessageList`,
  // getMessage: `${URL}/Message/GetMessage`,
  /**
   * @method GET
   * @param  parameters Object with parameters
   */
  // getMessageList: `${URL}/Message/GetMessageList`,
  // getAllMessagesForUser: `${URL}/Message/GetAllMessagesForUser`,

  /**
   * @method GET
   * @param  communicationGuid string
   */
  getAllMessagesForCommunication: `${URL}/Message/GetAllMessagesForCommunication`,

  /**
   * @method POST
   * @param  Message DataContainer serialized JSON
   */
  updateMessage: `${URL}/Message/UpdateMessage`,
  // deleteMessage: `${URL}/Message/DeleteMessage`,
  getLastMessages: `${URL}/Message/GetLastMessagesForUserCommunication`,
  getUnreadMessages: `${URL}/Message/GetUnreadMessages`,
};

settings.DBConfig = {
  name: 'PendlerPortal',
  version: 1,
  objectStoresMeta: [
    {
      store: 'images',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'guid', keypath: 'guid', options: { unique: true } },
      ],
    },
  ],
};

export default settings;
