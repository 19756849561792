import '@babel/polyfill';
import 'wicg-inert';
import React from 'react';
import { render } from 'react-dom';
import './css/main.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Router } from 'react-router-dom';
import customHistory from './js/logic/history';
import Bootstrap from './js/Bootstrap.jsx';
import appInfo from '../package';
import ErrorFallback from './js/routes/ErrorFallback';

Sentry.init({
  dsn: 'https://5d029bca24164d56aff622ad2d5b9a27@sentry.mpa-web.de/4',
  integrations: [
    new Integrations.BrowserTracing({
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation:
        Sentry.reactRouterV5Instrumentation(customHistory),
    }),
  ],
  release: `pendlerportal@${appInfo.version}`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment:
    process.env.NODE_ENV === 'development' ? 'development' : 'production',
});
render(
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <Router history={customHistory}>
      <Bootstrap />
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById('app'),
);
