import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import MapComponent from '../MapComponent';
import DataContainer from '../../logic/dataContainer';
import settings from '../../settings';
import Loader from '../Loader';
import DetailsBottom from '../DetailsBottom';
import SessionContext from '../../contexts/SessionContext';
import TripDetails from '../TripDetails';
import SloganSG from '../SloganSG';

const RideDetail = ({ tripGuid, type }) => {
  const { sessionData } = useContext(SessionContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [tripData, setTripData] = useState(null);

  useEffect(() => {
    if (tripGuid === '') {
      return;
    }
    setLoading(true);

    reqwest({
      url: type === 'Offer' ? settings.offer.get : settings.request.get,
      method: 'GET',
      data: {
        Token: sessionData?.token,
        Guid: tripGuid,
      },
    })
      .then((offerResponse) => {
        const dc = new DataContainer(offerResponse);
        const rideType = dc.get('Basic', 'Typ');
        const json = {
          Guid: dc.get('Basic', 'Guid'),
          Typ: rideType,
          ArrivalTime: dc.get('RequiredData', 'ArrivalTime'),
          CycleOptions: dc.get('RequiredData', 'CycleOptions'),
          Date: dc.get('RequiredData', 'Date'),
          DepartureTime: dc.get('RequiredData', 'DepartureTime'),
          Gender: dc.get('RequiredData', 'Gender'),
          MapsDestination: dc.get('RequiredData', 'MapsDestination'),
          MapsShape: dc.get('RequiredData', 'MapsShape'),
          MapsStart: dc.get('RequiredData', 'MapsStart'),
          MapsWaypoints: dc.get('RequiredData', 'MapsWaypoints'),
          Radius: dc.get('RequiredData', 'Radius'),
          Remarks: dc.get('RequiredData', 'Remarks'),
          SortDay: dc.get('RequiredData', 'SortDay'),
          TimeSlots: dc.get('RequiredData', 'TimeSlots'),
          UserGuid: dc.get('RequiredData', 'UserGuid'),
          WeekdayCycle: dc.get('RequiredData', 'WeekdayCycle'),
          Weekdays: dc.get('RequiredData', 'Weekdays'),
          GroupPreference: dc.get('RequiredData', 'GroupPreference'),
          CarType: dc.get('Car', 'CarType'),
          FreeSeats:
            rideType === 'Request'
              ? dc.get('RequiredData', 'FreeSeats')
              : dc.get('Car', 'FreeSeats'),
          Fuel: dc.get('Car', 'Fuel'),
          LicensePlate: dc.get('Car', 'LicensePlate'),
          Luggage:
            rideType === 'Request'
              ? dc.get('RequiredData', 'Luggage')
              : dc.get('Car', 'Luggage'),
          CarGuid: dc.get('Car', 'CarGuid'),
          Extras:
            rideType === 'Request'
              ? dc.get('RequiredData', 'Extras')
              : dc.get('Car', 'Extras'),
        };
        setTripData(json);
        setLoading(false);
      })
      .fail(() => {
        setTripData(null);
        setLoading(false);
      });
  }, [tripGuid]);

  if (!tripGuid) {
    return (
      <div className="RideDetail">
        <div className="RideDetail-Content">
          <div className="RideDetail-SloganSG">
            <SloganSG />
          </div>
        </div>
        <div className="RideDetail-footer">
          <DetailsBottom />
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="RideDetail">
        <div className="RideDetail-Content">
          <Loader />
        </div>
        <div className="RideDetail-footer">
          <DetailsBottom />
        </div>
      </div>
    );
  }

  if (tripData === null)
    return (
      <div className="RideDetail">
        <div className="RideDetail-Content">
          {t('ridedetail.error.loading')}
        </div>
        <div className="RideDetail-footer">
          <DetailsBottom />
        </div>
      </div>
    );

  let waypoints = tripData.MapsWaypoints;
  if (waypoints && waypoints !== '' && !Array.isArray(waypoints)) {
    waypoints = waypoints.replace(/"/g, "'").replace(/'/g, '"');
    waypoints = waypoints !== '' ? JSON.parse(waypoints) : '';
    tripData.MapsWaypoints = waypoints;
  }

  let timeSlots = tripData.TimeSlots;
  if (timeSlots !== '' && !Array.isArray(timeSlots)) {
    timeSlots = timeSlots.replace(/"/g, "'").replace(/'/g, '"');
    timeSlots = timeSlots !== '' ? JSON.parse(timeSlots) : '';
    tripData.TimeSlots = timeSlots;
  }

  return (
    <div className="RideDetail">
      <div className="RideDetail-Content">
        <MapComponent
          polyline={tripData.MapsShape}
          markers={tripData.MapsWaypoints}
        />
        <TripDetails data={tripData} detailsType="dashboard" />
      </div>
      <div className="RideDetail-footer">
        <DetailsBottom />
      </div>
    </div>
  );
};

RideDetail.propTypes = {
  tripGuid: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Offer', 'Request']).isRequired,
};

RideDetail.defaultProps = {};

export default RideDetail;
