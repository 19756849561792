import i18n from 'i18next';
import { isPreview } from './helpers';

const textOverride = async (className, languages = ['de']) => {
  return Promise.all(
    languages.map((language) => {
      const url = isPreview()
        ? `https://conf.pendlerportal.de/files/${className}/${language}-translations.json`
        : `https://cdn.pendlerportal.de/${className}/${language}-translations.json`;
      return fetch(url).then((response) => {
        if (response.ok) {
          response.json().then((customTranslations) => {
            // Merge custom translations with existing translations
            i18n.addResourceBundle(
              language,
              className,
              customTranslations,
              true,
              false,
            );
            i18n.setDefaultNamespace(className);
          });
        }
        return Promise.resolve();
      });
    }),
  );
};
export default textOverride;
