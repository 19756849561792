import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDataContainer } from '../../logic/dataContainer';
import settings from '../../settings';
import Card from '../Card';
import Loader from '../Loader';
import SessionContext from '../../contexts/SessionContext';

const UnratedRating = ({ entry, active, onClick }) => {
  const { sessionData } = useContext(SessionContext);
  const { t } = useTranslation();
  const {
    dataContainer: tripContainer,
    loading,
    error,
  } = useDataContainer({
    method: 'get',
    url: entry.Type === 'Offer' ? settings.offer.get : settings.request.get,
    data: {
      Token: sessionData?.token,
      Guid: entry.TripGuid,
    },
  });

  const handleClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick(entry.TripGuid);
    }
  };

  if (loading) {
    return (
      <div className="UnratedRating">
        <div className="UnratedRating-content">
          <Loader />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="UnratedRating">
        <div className="UnratedRating-content">
          <p>{t('unratedratings.error.loadtrip')}</p>{' '}
        </div>
      </div>
    );
  }

  return (
    <Card
      entry={tripContainer.get('RequiredData')}
      active={active}
      onClick={handleClick}
    />
  );
};

UnratedRating.propTypes = {
  entry: PropTypes.shape({
    TripGuid: PropTypes.string,
    Type: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

UnratedRating.defaultProps = {
  onClick: () => {},
  active: false,
};

export default UnratedRating;
