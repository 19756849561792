import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Form = forwardRef(({ onSubmit, children, ariaLabel }, ref) => {
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (onSubmit) {
      onSubmit(evt);
    }
  };

  return (
    <form
      className="Form"
      onSubmit={handleSubmit}
      aria-label={ariaLabel}
      ref={ref}
    >
      {children}
    </form>
  );
});

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

Form.defaultProps = {
  onSubmit: null,
};

export default Form;
