/**
 * A Session is available after user is logged in
 * @typedef {Object} Derivative
 * @property {string} name
 * @property {string} className
 * @property {array} urls
 */

/**
 * @name useSession
 * @description Contains data about the session. Like token and userdata. Data is saved in the local storage
 * @returns {Object} token, setToken, removeToken, userdata, setUserData, removeUserData
 */
const useSession = () => {
  /* Token */
  const token = localStorage.getItem('token');
  const setToken = (t) => {
    localStorage.setItem('token', t);
  };
  const removeToken = () => {
    localStorage.removeItem('token');
  };

  /* User Data  */
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const setUserData = (ud) => {
    localStorage.setItem('userdata', JSON.stringify(ud));
  };
  const removeUserData = () => {
    localStorage.removeItem('userdata');
  };

  const clearSession = () => {
    removeUserData();
    removeToken();
  };

  return {
    token,
    setToken,
    removeToken,
    userdata,
    setUserData,
    removeUserData,
    clearSession,
  };
};

export default useSession;
