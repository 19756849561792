import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import reqwest from 'reqwest';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import settings from '../../settings';
import DataContainer from '../../logic/dataContainer';
import usePageTitle from '../../hooks/usePageTitle';
import Button from '../../components/Buttons/Button';
import Page from '../../components/Layout/Page';
import UnratedRating from '../../components/UnratedRating';
import RatingDetail from '../../components/RatingDetail';
import UnratedRatingDetail from '../../components/UnratedRatingDetail';
import DashboardLinksMenu from '../../components/Dashboard/DashboardLinksMenu';
import Rating from '../../components/Rating';
import Loader from '../../components/Loader';
import SessionContext from '../../contexts/SessionContext';

const Ratings = ({ match }) => {
  const { t } = useTranslation();
  usePageTitle(t('ratingsPage.title'));
  const { sessionData } = useContext(SessionContext);
  const option = match.params.guid;
  const [activeRating, setActiveRating] = useState(option);
  const [ratedListLoading, setRatedListLoading] = useState(true);
  const [unratedListLoading, setUnratedListLoading] = useState(true);
  const [ratedDataContainer, setRatedDataContainer] = useState();
  const [rating, setRating] = useState();
  const [unratedDataContainer, setUnratedDataContainer] = useState();

  useEffect(() => {
    let savedRating;

    reqwest({
      method: 'GET',
      url: settings.booking.getUnratedBookings,
      data: {
        Token: sessionData?.token,
        UserGuid: sessionData?.userGuid,
      },
    }).then((listResponse) => {
      const unratedContainers = new DataContainer(listResponse);

      setUnratedDataContainer(
        unratedContainers.getMany('Entry').filter((e) => {
          return e.Guid;
        }),
      );
      setUnratedListLoading(false);

      if (unratedContainers.getMany('Entry').length > 0) {
        savedRating = unratedContainers.containers[0].Params;
        setRating(savedRating);
        setActiveRating(savedRating.Guid);
      }
    });

    reqwest({
      method: 'GET',
      url: settings.booking.getRatedBookings,
      data: {
        Token: sessionData?.token,
        UserGuid: sessionData?.userGuid,
      },
    }).then((listResponse) => {
      const ratedContainers = new DataContainer(listResponse);

      setRatedDataContainer(
        ratedContainers.getMany('Entry').filter((e) => {
          return e.Guid;
        }),
      );
      setRatedListLoading(false);

      if (
        savedRating === undefined &&
        ratedContainers.getMany('Entry').length > 0
      ) {
        savedRating = ratedContainers.containers[0].Params;
        setRating(savedRating);
        setActiveRating(savedRating.Guid);
      }
    });
  }, [sessionData.token, sessionData.userGuid]);

  useEffect(() => {
    if (option === 'ratings') {
      if (unratedDataContainer.containers.length) {
        setActiveRating(unratedDataContainer.get('Entry', 'Guid'));
      } else if (ratedDataContainer.containers.length) {
        setActiveRating(ratedDataContainer.get('Entry', 'Guid'));
      }
    }
  }, [option, ratedDataContainer, unratedDataContainer]);

  const setActive = (entry) => {
    setRating(entry);
    setActiveRating(entry.Guid);
    window.scroll({
      top: 400,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="Ratings">
      <Page className="ContentSection--ratings">
        <DashboardLinksMenu />
        <br />
        <div
          className={`ContentSection-split ${
            activeRating ? 'hideLeft' : 'hideRight'
          }`}
        >
          <div className="ContentSection-left">
            <h2 className="underline">
              {t('dashboard.ratings.your_unrated_bookings')}
            </h2>
            {!unratedListLoading ? (
              <>
                {unratedDataContainer?.map((entry) => {
                  return (
                    <UnratedRating
                      key={entry.Guid}
                      entry={entry}
                      active={activeRating === entry.Guid}
                      onClick={() => {
                        setActive(entry);
                      }}
                    />
                  );
                })}
                {unratedDataContainer?.length === 0 && (
                  <div className="ContentSection-no_results">
                    {t('ratingsPage.none_rated')}
                  </div>
                )}
              </>
            ) : (
              <Loader />
            )}
            <br />
            <h2 className="underline">
              {t('dashboard.ratings.your_rated_bookings')}
            </h2>
            {!ratedListLoading ? (
              <>
                {ratedDataContainer?.map((entry) => {
                  return (
                    <Rating
                      entry={entry}
                      active={activeRating === entry.Guid}
                      onClick={() => {
                        setActive(entry);
                      }}
                    />
                  );
                })}
                {ratedDataContainer?.length === 0 && (
                  <div className="ContentSection-no_results">
                    {t('ratingsPage.not_rated')}
                  </div>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
          <div className="ContentSection-right">
            <Button
              className="Back-Button"
              onClick={() => {
                return setActiveRating(null);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />{' '}
              {t('ratingsPage.buttons.backMobile')}
            </Button>
            {activeRating && rating && rating.HasUserRated === false && (
              <UnratedRatingDetail rating={rating} />
            )}
            {activeRating && rating && rating.HasUserRated === true && (
              <RatingDetail rating={rating} />
            )}
          </div>
        </div>
      </Page>
    </div>
  );
};

Ratings.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      guid: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

Ratings.defaultProps = {};

export default Ratings;
