import { isPreview } from './helpers';

const styleOverride = (className) => {
  const isStyleAlreadyLoaded = () => {
    const selectedLinks = document.head.querySelectorAll(
      `link[id="${className}"]`,
    );
    return selectedLinks.length > 0;
  };

  const createAndAppendLink = () => {
    const link = document.createElement('link');
    link.id = className;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = isPreview()
      ? `https://conf.pendlerportal.de/files/${className}/variables.css`
      : `https://cdn.pendlerportal.de/${className}/variables.css`;
    document.head.appendChild(link);
  };

  if (!isStyleAlreadyLoaded()) {
    createAndAppendLink();
  }
};
export default styleOverride;
