import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import Button from '../Buttons/Button';
import useDerivative from '../../hooks/useDerivative';
import TransitConnection from '../TransitConnection';

const TransitDetails = ({
  title,
  startPoint,
  endPoint,
  departureTime,
  arrivalTime,
}) => {
  const currentDerivative = useDerivative();
  const [endTransitData, setEndTransitData] = useState(null);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: {
          query: startPoint,
        },
        destination: {
          query: endPoint,
        },
        travelMode: google.maps.TravelMode.TRANSIT,
        transitOptions: {
          departureTime: new Date(departureTime),
          arrivalTime: new Date(arrivalTime),
        },
        provideRouteAlternatives: false,
      },
      (response, status) => {
        if (status === 'OK') {
          setEndTransitData(response.routes[0]);
        } else {
          console.warn('API request for maps failed');
        }
      },
    );
  }, [startPoint, endPoint, departureTime, arrivalTime]);

  return (
    <div className="TransitDetails">
      {endTransitData && (
        <div>
          <button
            className={`TransitDetails-button ${show ? 'open' : ''}`}
            type="button"
            onClick={() => setShow(!show)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
            {title}
          </button>
          {show && (
            <div className="TransitDetails-content">
              <TransitConnection entry={endTransitData} showSteps />
              {currentDerivative.transitProvider && (
                <div className="SD-transit_provider_links">
                  {currentDerivative.transitProvider.map((tp) => {
                    return (
                      <Button shape="round" href={tp.url}>
                        {tp.name}
                      </Button>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

TransitDetails.defaultProps = {
  title: '',
  departureTime: null,
  arrivalTime: null,
};

TransitDetails.propTypes = {
  title: PropTypes.string,
  startPoint: PropTypes.string.isRequired,
  endPoint: PropTypes.string.isRequired,
  departureTime: PropTypes.string,
  arrivalTime: PropTypes.string,
};

export default TransitDetails;
